import axios from 'axios';

export const getPlannedHitsData = async (hitdates, reportBy, product_name) => {
  let data = JSON.stringify({
    "startdate": hitdates.startdate,
    "enddate": hitdates.enddate,
    "reportby": reportBy,
    "product_name": product_name
  });
  var config = {
    method: 'POST',
    url: `/api/getPlannedHits`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  return axios.request(config);
};

export const getActualHitsData = async (hitdates, reportBy, product_name) => {
  let data = JSON.stringify({
    "startdate": hitdates.startdate,
    "enddate": hitdates.enddate,
    "reportby": reportBy,
    "product_name": product_name
  });
  var config = {
    method: 'POST',
    url: `/api/getActualHits`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  return axios.request(config);
};

export const getPlannedRevenueData = async (hitdates, reportBy, product_name) => {
  let data = JSON.stringify({
    "startdate": hitdates.startdate,
    "enddate": hitdates.enddate,
    "reportby": reportBy,
    "product_name": product_name
  });
  var config = {
    method: 'POST',
    url: `/api/getPlannedRevenue`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  return axios.request(config);
};

export const getActualRevenueData = async (hitdates, reportBy, product_name) => {
  let data = JSON.stringify({
    "startdate": hitdates.startdate,
    "enddate": hitdates.enddate,
    "reportby": reportBy,
    "product_name": product_name
  });
  var config = {
    method: 'POST',
    url: `/api/getActualRevenue`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  return axios.request(config);
};

export const getPlannedHitsQuarterlySummaryByMonth = (hitdates) => {
  let data = JSON.stringify({
    "startdate": hitdates.hit_date_start,
    "enddate": hitdates.hit_date_end
  });
  var config = {
    method: 'POST',
    url: `/api/v2/getPlannedHitsQuarterlySummaryByMonth`,
    headers: { 'Content-Type': 'application/json' },
    data: data
  };
  return axios.request(config);
}

export const getPlatformWiseHitsRevenue = (startdate, enddate, product_name, reportby) => {
  return axios.post(`/api/v2/${product_name}/platform-wise-hits-revenue`, {
    startdate,
    enddate,
    reportby

  });
}

export const getPlatformWiseTeamWiseHitsRevenue = (startdate, enddate, product_name, reportby) => {
  return axios.post(`/api/v2/${product_name}/platform-wise-team-wise-hits-revenue`, {
    startdate,
    enddate,
    reportby
  });
}

export const getDateWiseHitsRevenueSummary = (startdate, enddate, product_name, reportby) => {
  // return axios.post(`/api/v2/${product_name}/date-wise-hits-revenue-summary`, {
  return axios.post(`/api/v2/${product_name}/date-wise-hits-revenue-summary`, {
    startdate,
    enddate,
    reportby
  });
}

export const getTeamWiseHitsRevenueSummary = (startdate, enddate, product_name, reportby) => {
  return axios.post(`/api/v2/${product_name}/team-wise-hits-revenue-summary`, {
    startdate,
    enddate,
    reportby
  });
}

export const getTeamMemberWiseHitsRevenueSummary = (startdate, enddate, product_name, reportby) => {
  return axios.post(`/api/v2/${product_name}/team-member-wise-hits-revenue-summary`, {
    startdate,
    enddate,
    reportby
  });
}

export const getTeamWiseTeamMemberWiseHitsRevenueSummary = (startdate, enddate, product_name, reportby) => {
  return axios.post(`/api/v2/${product_name}/team-wise-team-member-wise-hits-revenue-summary`, {
    startdate,
    enddate,
    reportby
  });
}

export const getTeamMembersSubtotaledByTeamHitsRevenueSummary = (startdate, enddate, timePeriod, product_name) => {
  return axios.post(`/api/v2/get-planned-hits-by-teammembers-subtotaled-by-team`, {
    startdate,
    enddate,
    reportby: timePeriod,
    product_name: product_name
  });
}

export const getTeamMembersSubtotaledByTeamHitsRevenueSummaryNew = (startdate, enddate, timePeriod, product_name) => {
  return axios.post(`/api/v2/${product_name}/team-wise-team-member-wise-hits-revenue-summary`, {
    startdate,
    enddate,
    reportby: timePeriod,
    product_name: product_name
  });
}