import React, { useEffect, useState, useContext } from 'react'
import { SelectedDateRangeContext } from '../pages/monthly.detail.page'
import { Box, colors, Paper, Tabs, Tab } from '@material-ui/core';

import AddActualsModalComponent from './add-actuals-modal.component';
import MonthlySummaryByTeamMemberDataTable from './MonthlySummaryByTeamMemberDataTable';
import MonthlySummaryByTeamDataTable from './MonthlySummaryByTeamDataTable';
import MonthlySummaryByPlatformDataTable from './MonthlySummaryByPlatformDataTable';
import MonthlySummaryByDateOverallDataTable from './MonthlySummaryByDateOverallDataTable';
import MonthlySummaryByPlatformByTeamDataTable from './MonthlySummaryByPlatformByTeamDataTable';

import "bootstrap/dist/css/bootstrap.min.css";
import MonthlySummaryByTeamMemberSubtotaledByTeamDataTable from './MonthlySummaryByTeamMemberSubtotaledByTeamDataTable';

import { getPlannedHitsData, getActualHitsData, getPlannedRevenueData, getActualRevenueData } from '../services/reports.service';
import PlatfromWiseTeamWiseTab from './PlatfromWiseTeamWiseTab';

const MonthlyDetailDataTableComponent = (props) => {

    const { dateRangeObj, timePeriod, product, plannedHitsByPlatformByTeam, actualHitsByPlatformByTeam, plannedRevenueByPlatformByTeam, actualRevenueByPlatformByTeam } = useContext(SelectedDateRangeContext);

    const [addActualsModalOpen, setAddActualsModalOPen] = useState();

    const [, setDisplayChart] = useState(props.displaychart);

    const [value, setValue] = useState(0);
    const handleTabs = (e, val) => { setValue(val) };

    const handleAddActualsModalClose = () => setAddActualsModalOPen(false);

    // const [plannedHitsByPlatformByTeam, setPlannedHitsByPlatformByTeam] = useState([]);
    // const [actualHitsByPlatformByTeam, setActualHitsByPlatformByTeam] = useState([]);
    // const [plannedRevenueByPlatformByTeam, setPlannedRevenueByPlatformByTeam] = useState([]);
    // const [actualRevenueByPlatformByTeam, setActualRevenueByPlatformByTeam] = useState([]);

    // useEffect(async () => {
    //     // console.log('*****   LOADING DATA TABLE COMPONENT   *****');
    //     // const getPlatformByTeamData = async () => {
    //     setPlannedHitsByPlatformByTeam(await getPlannedHitsData(dateRangeObj[0], timePeriod[0], product[0]))
    //     setActualHitsByPlatformByTeam(await getActualHitsData(dateRangeObj[0], timePeriod[0], product[0]))
    //     setPlannedRevenueByPlatformByTeam(await getPlannedRevenueData(dateRangeObj[0], timePeriod[0], product[0]))
    //     setActualRevenueByPlatformByTeam(await getActualRevenueData(dateRangeObj[0], timePeriod[0], product[0]))
    //     setDisplayChart(props.displaychart);
    //     // }
    //     // getPlatformByTeamData();

    //     return () => clearImmediate();
    // }, []);

    return (
        <>
            <Box width={'100%'} padding={1}>
                <Paper square>
                    <Tabs value={value} onChange={handleTabs} variant="standard" indicatorColor="secondary" textColor="primary">
                        <Tab label="Date Wise Summary " />
                        <Tab label="Platform Summary " />
                        <Tab label="Team Wise " />
                        <Tab label="Team Member Wise " />
                        <Tab label="Platform Wise / Team Wise" />
                        <Tab label="Team Members Subtotaled by Team" />
                    </Tabs>
                </Paper>
                <Tabpanel value={value} index={0}>
                    {/* ***** Date Wise Summary Report ***** */}
                    <MonthlySummaryByDateOverallDataTable />
                </Tabpanel>
                <Tabpanel value={value} index={1}>
                    {/* ***** Platform Summary Report ***** */}
                    <MonthlySummaryByPlatformDataTable />
                </Tabpanel>
                <Tabpanel value={value} index={2}>
                    {/* ***** Team Wise Report ***** */}
                    <MonthlySummaryByTeamDataTable />
                </Tabpanel>
                <Tabpanel value={value} index={3}>
                    {/* ***** Team Member Wise Report ***** */}
                    <MonthlySummaryByTeamMemberDataTable />
                </Tabpanel>
                <Tabpanel value={value} index={4}>
                    {/* ***** Platform Wise / Team Wise Report ***** */}
                    {/* <Box margin={1}>
                        <div style={{ textAlign: "left", fontWeight: 700, marginTop: 15, color: colors.purple[500] }} className="h5">Platform Wise / Team Wise Report</div>
                    </Box> */}
                    {/* <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'linkedin'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />

                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'linkedin'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'linkedin'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'linkedin'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'twitter'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'twitter'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'twitter'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'twitter'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} />

                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'twitter'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'twitter'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'twitter'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'twitter'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'twitter'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'twitter'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'twitter'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'twitter'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedHitsByPlatformByTeam} platform={'twitter'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualHitsByPlatformByTeam} platform={'twitter'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={plannedRevenueByPlatformByTeam} platform={'twitter'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable PlatformByTeamData={actualRevenueByPlatformByTeam} platform={'twitter'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} /> */}
                    <PlatfromWiseTeamWiseTab />
                </Tabpanel>

                <Tabpanel value={value} index={5}>
                    {/* ***** Team Memeber Subtotaled by Team ***** */}
                    <Box width="100%" display='flex' justifyContent='space-between'  >
                        <Box width='48%'>
                            <Box border={1} borderColor={colors.blue[200]} bgcolor={colors.blue[50]} borderRadius={3} marginTop={3} p={1} >Planned Hits</Box>
                            <MonthlySummaryByTeamMemberSubtotaledByTeamDataTable HitsValue={'Planned Hits'} />
                            <Box border={1} marginTop={3} p={1} borderColor={colors.deepOrange[200]} borderRadius={3} bgcolor={colors.deepOrange[50]}>Planned Revenue</Box>
                            <MonthlySummaryByTeamMemberSubtotaledByTeamDataTable HitsValue={'Planned Revenue'} />
                        </Box>
                        <Box width='48%'>
                            <Box border={1} borderColor={colors.blue[200]} bgcolor={colors.blue[50]} borderRadius={3} marginTop={3} p={1}>Actual Hits</Box>
                            <MonthlySummaryByTeamMemberSubtotaledByTeamDataTable HitsValue={'Actual Hits'} />
                            <Box border={1} marginTop={3} p={1} borderColor={colors.deepOrange[200]} borderRadius={3} bgcolor={colors.deepOrange[50]}>Actual Revenue</Box>
                            <MonthlySummaryByTeamMemberSubtotaledByTeamDataTable HitsValue={'Actual Revenue'} />
                        </Box>
                    </Box>
                </Tabpanel>
            </Box>
            {addActualsModalOpen
                ? <Box bgcolor={colors.blueGrey[50]} >
                    <AddActualsModalComponent
                        openFlag={addActualsModalOpen}
                        handleModalClose={handleAddActualsModalClose} />
                </Box>
                : null}
        </>
    )
    function Tabpanel(props) {
        const { children, value, index } = props;
        return <>{value === index && <h1>{children}</h1>}</>;
    }
};

export default MonthlyDetailDataTableComponent;
