import React, { useEffect, useState, useContext } from 'react'
import { SelectedDateRangeContext } from '../pages/monthly.detail.page'
import { Box, colors, Paper, Tabs, Tab } from '@material-ui/core';

import MonthlySummaryByPlatformByTeamDataTable from './MonthlySummaryByPlatformByTeamDataTable';

import "bootstrap/dist/css/bootstrap.min.css";

const PlatfromWiseTeamWiseTab = () => {
    const [value, setValue] = useState(0);
    const handleTabs = (e, val) => { setValue(val) };
    return (
        <>
            <Box width={'100%'} padding={1}>
                <Paper square>
                    <Tabs value={value} onChange={handleTabs} variant="standard" indicatorColor="secondary" textColor="primary">
                        <Tab label="Linkedin " />
                        <Tab label="Twitter " />
                        <Tab label="Facebook " />
                        <Tab label="Reddit " />
                        <Tab label="Organic Seo" />
                        <Tab label="Seo" />
                        <Tab label="Email" />
                    </Tabs>
                </Paper>

                <Tabpanel value={value} index={0}>
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'linkedin'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                </Tabpanel>

                <Tabpanel value={value} index={1}>
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'twitter'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                </Tabpanel>
                <Tabpanel value={value} index={2}>
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'facebook'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                </Tabpanel>
                <Tabpanel value={value} index={3}>
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'reddit'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                </Tabpanel>
                <Tabpanel value={value} index={4}>
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'organic seo'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                </Tabpanel>

                <Tabpanel value={value} index={5}>
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'seo'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                </Tabpanel>
                <Tabpanel value={value} index={6}>
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Hits'} plnActHitsRev={'planned_hit'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Hits'} plnActHitsRev={'actual_hit'} />
                    <hr />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'editors'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'writters'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'social'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'seo'} PlannedOrActual={'Planned'} HitsOrRevenue={'Revenue'} plnActHitsRev={'planned_revenue'} />

                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'editors'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'writters'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'social'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                    <MonthlySummaryByPlatformByTeamDataTable platform={'email'} teamName={'seo'} PlannedOrActual={'Actual'} HitsOrRevenue={'Revenue'} plnActHitsRev={'actual_revenue'} />
                </Tabpanel>
            </Box>
        </>
    )
    function Tabpanel(props) {
        const { children, value, index } = props;
        return <>{value === index && <h1>{children}</h1>}</>;
    }
}

export default PlatfromWiseTeamWiseTab