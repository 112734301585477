import React from 'react';
import Chart from 'react-apexcharts';

const PieChartComponent = (props) => {

  return (
    <Chart
      type="donut"
      height={'auto'}
      series={props.seriesData}
      options={{
        chart: {
          type: "donut",
          animations: {
            enabled: false,
          }
        },
        title: { text: props.chartTitle, style: { fontSize: '12px', textTransform: 'capitalize !important' } },
        noData: { text: 'No Data To Build Chart', align: 'center', verticalAlign: 'middle' },
        theme: { mode: 'light', palette: 'palette2' },
        legend: { show: false, position: 'right', horizontalAlign: 'right', textTransform: 'capitalize !important' },
        labels: props.LablesData,
        tooltip: { y: {formatter: val => props.hitsRevenue === 'revenue' ? parseFloat(val).toFixed(2) : parseInt(val)} },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
          }
        }]
      }}
    />
  )
}

export default PieChartComponent;
