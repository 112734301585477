import { colors, LinearProgress, makeStyles } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import axios from 'axios';
import React, { useState, useEffect } from 'react';

const useStyles = makeStyles((theme) => ({
    MuiDataGrid: {
        '& .MuiDataGrid-cell': {
            color: colors.grey[700],
            fontSize: "0.75rem !important",
            margin: "0 !important",
        },
        '& .MuiDataGrid-columnHeader': {
            color: colors.blue[900],
            fontSize: "0.4rem !important",
            fontWeight: "500 !important",
            lineHeight: "1.1 !important",
            height: '75px !important',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: "0.75rem !important",
            fontWeight: '500 !important',
            lineHeight: '1.1 !important',
            whiteSpace: 'break-spaces !important',
        }
    },
    MuiDataGridRoot: {
        padding: '0 !important',
        margin: '0 !important',
    },
}));

const Rolling12moQuarterDetailHitsComponent = (props) => {
    const classes = useStyles();

    const [dataLoading, setdataLoading] = useState(true);
    const [Rolling12moQuarterDetailHits, setRolling12moQuarterDetailHits] = useState([]);
    const [Rolling12moQuarterDetailHitsColumns, setRolling12moQuarterDetailHitsColumns] = useState([]);

    const prepareColumns = async (objKeys) => {
        let colsArray = [];
        Object.keys(objKeys).forEach((col, idx) => {
            let wd, hide, type, valueFormatter;
            valueFormatter = (params) => {
                if (isNaN(params.value))
                    return params.value
                else
                    return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(params.value)
            }
            idx === 0 ? hide = true : hide = false;
            idx < 3 ? wd = 125 : wd = 110;
            idx < 3 ? type = 'text' : type = 'number';
            let c = { field: col, headerName: col.toUpperCase(), width: wd, hide: hide, type: type, sortable: false, valueFormatter };
            colsArray.push(c);
        });
        return colsArray;
    };

    useEffect(() => {
        const fetchData = async () => {
            setdataLoading(true)
            let requestConfig = {
                method: 'POST', url: `/api/${props.dataUrl}`, headers: { 'Content-Type': 'application/json' },
                data: {
                    startdate: props.dateRange.startdate,
                    enddate: props.dateRange.enddate,
                    product_name: props.product
                },
            };
            console.log(`Calling API ${requestConfig.url} with Options :: ${JSON.stringify(requestConfig)} `);
            await axios.request(requestConfig).then(async (resp) => {
                console.log(`${requestConfig.url} API RESP ::::: `, resp.data);
                if (resp.data.length > 0) {
                    let indexed = resp.data.map((row, idx) => ({ ...row, id: idx }))
                    setRolling12moQuarterDetailHits(indexed);
                    setRolling12moQuarterDetailHitsColumns(await prepareColumns(resp.data[0]));
                }
            }).catch(err => {
                console.log(`Error from ${requestConfig.url} ---- ${err}`);
            });
            setdataLoading(false);
        };

        fetchData();

        return () => clearImmediate();
    }, [props.product]);

    console.log("Rolling12moQuarterDetailHits :::", Rolling12moQuarterDetailHits)

    return (
        <>{dataLoading ? <div><LinearProgress color="secondary" /></div> :
            <DataGrid
                className={classes.MuiDataGrid}
                disableColumnMenu={true}
                disableColumnFilter
                disableSelectionOnClick
                isCellEditable={false}
                hideFooter
                autoHeight
                density="compact"
                key={Rolling12moQuarterDetailHits.id}
                rows={Rolling12moQuarterDetailHits}
                columns={Rolling12moQuarterDetailHitsColumns}
            />
        }</>
    )
}

export default Rolling12moQuarterDetailHitsComponent;
