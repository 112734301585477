import React from 'react'

const PageNotFound = () => {
    return (
        <div>
            <h2 style={{color: 'gray'}}>404</h2>
         <h3>Page Not Found</h3>   
        </div>
    )
}

export default PageNotFound;
