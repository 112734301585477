import { DataGrid } from '@material-ui/data-grid';
import { makeStyles, colors, Box, LinearProgress } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import { SelectedDateRangeContext } from '../pages/monthly.detail.page'
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 125,
        marginTop: 0,
        marginBottom: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    MuiDataGrid: {
        '& .MuiDataGrid-cell': {
            color: colors.grey[700],
            fontSize: "0.75rem !important",
            margin: "0 !important",
        },
        '& .MuiDataGrid-row': {
            '&:last-child': {
            }
        },
        '& .MuiDataGrid-columnHeader': {
            color: colors.blue[900],
            fontSize: "0.4rem !important",
            fontWeight: "500 !important",
            lineHeight: "1.1 !important",
            height: '75px !important',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: "0.75rem !important",
            fontWeight: '500 !important',
            lineHeight: '1.1 !important',
            whiteSpace: 'break-spaces !important',
        }
    },
    MuiDataGridRoot: {
        padding: '0 !important',
        margin: '0 !important',
    },
    tableMainLabel: { textAlign: "left", marginTop: 15, marginLeft: 10, textTransform: 'capitalize' },
    tableSubLabel: { textAlign: "left", marginTop: 15, marginLeft: 20, textTransform: 'capitalize' }
}));

const MonthlySummaryByTeamMemberSubtotaledByTeamDataTable = (props) => {
    const classes = useStyles();
    const { plnHitsTeamMembersSubtotaledByTeam, actHitsTeamMembersSubtotaledByTeam, plnRevTeamMembersSubtotaledByTeam, actRevTeamMembersSubtotaledByTeam, timePeriod } = useContext(SelectedDateRangeContext)

    const [unqTeams, setUnqTeams] = useState([]);
    const [datacols, setDatacols] = useState([]);
    const [datarows, setDatarows] = useState([]);


    useEffect(async () => {
        if (props.HitsValue === 'Planned Hits') {
            let data = await plnHitsTeamMembersSubtotaledByTeam[0][0];
            let unqTms = new Set(data.map(r => r.team));
            unqTms = Array.from(unqTms);
            setUnqTeams(unqTms)

            for (const team of unqTms) {
                let fd = await data.filter(r => r.team === team);
                setDatarows(prev => [...prev, fd])
            };

            let cols = await Object.keys(data[0]).map((col, idx) => {
                if (idx < 2)
                    return { field: col, description: col, headerName: col, hide: true, sortable: false }
                else if (idx === 3)
                    return {
                        field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false, valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(param.value) : null
                        }
                    }
                else if (idx < 4)
                    return { field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false }
                else
                    return {
                        field: col, 
                        headerName: timePeriod[0] === 'day' ? format(new Date(col), "dd") : col, 
                        width: timePeriod[0] === 'day' ? 20 : 65, 
                        sortable: false, 
                        type: 'number',
                        description: timePeriod[0] === 'day' ? format(new Date(col), "yyyy-MM-dd") : col, 
                        valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(param.value) : null
                        }
                    }
            });
            setDatacols(cols);
            return
        } else if (props.HitsValue === 'Actual Hits') {
            let unqTms = new Set(actHitsTeamMembersSubtotaledByTeam[0][0].map(r => r.team));
            unqTms = Array.from(unqTms);
            setUnqTeams(unqTms)

            for (const team of unqTms) {
                let fd = await actHitsTeamMembersSubtotaledByTeam[0][0].filter(r => r.team === team);
                setDatarows(prev => [...prev, fd])
            };

            let cols = await Object.keys(actHitsTeamMembersSubtotaledByTeam[0][0][0]).map((col, idx) => {
                if (idx < 2)
                    return { field: col, description: col, headerName: col, hide: true, sortable: false }
                else if (idx === 3)
                    return {
                        field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false, valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(param.value) : null
                        }
                    }
                else if (idx < 4)
                    return { field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false }
                else
                    return {
                        field: col, 
                        headerName: timePeriod[0] === 'day' ? format(new Date(col), "dd") : col, 
                        width: timePeriod[0] === 'day' ? 20 : 65, 
                        sortable: false, 
                        type: 'number',
                        description: timePeriod[0] === 'day' ? format(new Date(col), "yyyy-MM-dd") : col, 
                        valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(param.value) : null
                        }
                    }
            });
            setDatacols(cols);
            return
        } else if (props.HitsValue === 'Planned Revenue') {
            let unqTms = new Set(plnRevTeamMembersSubtotaledByTeam[0][0].map(r => r.team));
            unqTms = Array.from(unqTms);
            setUnqTeams(unqTms)

            for (const team of unqTms) {
                let fd = await plnRevTeamMembersSubtotaledByTeam[0][0].filter(r => r.team === team);
                setDatarows(prev => [...prev, fd])
            };

            let cols = await Object.keys(plnRevTeamMembersSubtotaledByTeam[0][0][0]).map((col, idx) => {
                console.log('Planned Revenue Cols :: ', idx, col)
                if (idx < 2)
                    return { field: col, description: col, headerName: col, hide: true, sortable: false }
                else if (idx === 3)
                    return {
                        field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false, valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(param.value) : null
                        }
                    }
                else if (idx < 4)
                    return { field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false }
                else
                    return {
                        field: col, 
                        headerName: timePeriod[0] === 'day' ? format(new Date(col), "dd") : col, 
                        width: timePeriod[0] === 'day' ? 20 : 65, 
                        sortable: false, 
                        type: 'number',
                        description: timePeriod[0] === 'day' ? format(new Date(col), "yyyy-MM-dd") : col, 
                        valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(param.value) : null
                        }
                    }
            });
            setDatacols(cols);
            return
        } else if (props.HitsValue === 'Actual Revenue') {
            let unqTms = new Set(actRevTeamMembersSubtotaledByTeam[0][0].map(r => r.team));
            unqTms = Array.from(unqTms);
            setUnqTeams(unqTms)

            for (const team of unqTms) {
                let fd = await actRevTeamMembersSubtotaledByTeam[0][0].filter(r => r.team === team);
                setDatarows(prev => [...prev, fd])
            };

            let cols = await Object.keys(actRevTeamMembersSubtotaledByTeam[0][0][0]).map((col, idx) => {
                if (idx < 2)
                    return { field: col, description: col, headerName: col, hide: true, sortable: false }
                else if (idx === 3)
                    return {
                        field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false, valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(param.value) : null
                        }
                    }
                else if (idx < 4)
                    return { field: col, description: col, headerName: col.toUpperCase().replace("_", " "), width: 90, sortable: false }
                else
                    return {
                        field: col, 
                        headerName: timePeriod[0] === 'day' ? format(new Date(col), "dd") : col, 
                        width: timePeriod[0] === 'day' ? 20 : 65, 
                        sortable: false, 
                        type: 'number',
                        description: timePeriod[0] === 'day' ? format(new Date(col), "yyyy-MM-dd") : col, 
                        valueFormatter: (param) => {
                            return param.value ? Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(param.value) : null
                        }
                    }
            });
            setDatacols(cols);
            return
        }

        return () => clearImmediate();
    }, []);

    return (
        <>
            <Box display="flex" width="100%" flexDirection="row" flexWrap="wrap">
                {unqTeams.length > 0 && unqTeams.map((team, idx) =>
                    <>
                        <h5 style={{ textAlign: "start", marginTop: 20, marginBottom: 5, marginLeft: 5 }}>
                            <span style={{ color: colors.blue[900], fontWeight: 500 }}>{team}&nbsp;Team</span>
                        </h5>
                        <>{datarows.length > 0 && datacols.length > 0 &&
                            <Box width='100%'>
                                <DataGrid
                                    className={classes.MuiDataGrid}
                                    disableColumnMenu
                                    hideFooter
                                    autoHeight
                                    density="compact"
                                    key={datarows[idx]['id']}
                                    rows={datarows[idx]}
                                    disableColumnFilter
                                    columns={datacols}
                                />
                            </Box>}</>
                    </>)
                }
            </Box>
        </>
    )
}

export default MonthlySummaryByTeamMemberSubtotaledByTeamDataTable;



{/* <DataGrid
    className={classes.MuiDataGrid}
    disableColumnMenu
    hideFooter
    autoHeight
    density="compact"
    key={idx}
    rows={item}
    disableColumnFilter
    columns={Object.keys(item[0]).map((col, idx) => {
        if (idx < 2)
            return { field: col, description: col, headerName: col.toLocaleLowerCase().replace(/_/gmi, ' '), hide: true, sortable: false }
        else if (idx === 2)
            return { field: col, description: col, headerName: "NAME", width: 120, sortable: false }
        else if (col === "ROW TOTAL")
            return { field: col, description: col, headerName: col.toUpperCase().replace("_"," "), width: 80, sortable: false }
        else
            return { field: col, headerName: format(new Date(col), "dd"), width: 20, sortable: false, description: format(new Date(col), "yyyy-MM-dd") }
    })}
/> */}