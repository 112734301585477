import React, { useContext, useEffect, useState } from 'react'
import { Box, colors, Tabs, Tab, Grid, Paper } from '@material-ui/core';
import { SelectedDateRangeContext } from '../pages/monthly.detail.page'

import Rolling30DaysLineChartRevenueComponent from './Rolling30days.Linechart.Revenue.component';
import PieChartComponent from './pie.chart.component';
import AddActualsModalComponent from './add-actuals-modal.component';
import Rolling30DaysLineChartHitsComponent from './Rolling30days.Linechart.Hits.component';
import LineChartTeamMembersHitsComponent from './Linechart.TeamMembers.Hits.component';
import LineChartTeamMembersRevenueComponent from './Linechart.TeamMembers.Revenue.component';
import LineChartPlatformWiseHitsComponent from './Linechart.PlatformWise.Hits.component';
import LineChartPlatformWiseRevenueComponent from './Linechart.PlatformWise.Revenue.component';
import { format } from 'date-fns';
import LinechartTeamMembersSubtotaledByTeam from './Linechart.TeamMembers.Subtotaled.ByTeam';

const MonthlyDetailDataChartsComponent = (props) => {
    const { dateRangeObj, teamWiseRevSeriesData, teamWiseRevSeriesLabels, memberWiseRevSeriesData, memberWiseRevSeriesLabels, platformWiseRevSeriesData, 
        platformWiseRevSeriesLabels, teamWiseHitsSeriesData, teamWiseHitsSeriesLabels, memberWiseHitsSeriesData, memberWiseHitsSeriesLabels, platformWiseHitsSeriesData, 
        platformWiseHitsSeriesLabels, product, setProduct } = useContext(SelectedDateRangeContext);

    // console.log("Pie Chart Component :::", teamWiseRevSeriesData, teamWiseRevSeriesLabels);

    const [dateRange] = dateRangeObj;
    const [DisplayChart] = useState(props.displayChart);
    const [toggleDataLabel] = useState();

    const [product_name] = useState(product[0]);
    const [addActualsModalOpen, setAddActualsModalOPen] = useState();

    const handleAddActualsModalClose = () => setAddActualsModalOPen(false);

    const [value, setValue] = useState(0);
    const handleTabs = (e, val) => { setValue(val) };

    useEffect(() => { }, [DisplayChart]);

    return (
        <>
            <Paper square>
                <Tabs value={value} onChange={handleTabs} variant="standard" indicatorColor="secondary" textColor="primary">
                    <Tab label="Hits/Revenue Planned/Actual" />
                    <Tab label="Member Wise Hits/Revenue" />
                    <Tab label="Platform Wise Hits/Revenue" />
                    <Tab label="Team Members Subtotaled By Team" />
                </Tabs>
            </Paper>
            <Tabpanel value={value} index={0}>
                <Grid item md={12} sm={12}>
                    <h4 style={{ margin: 10, color: colors.blueGrey[700], textAlign: 'left', }}>{format(dateRange.startdate, "MMM dd, yyyy")} - {format(dateRange.enddate, "MMM dd, yyyy")}</h4>
                    <Box display="flex" width={'100%'} flexDirection="row" flexGrow={1} flexWrap="wrap" marginTop={0.75}>
                        <Box width={'24%'}>
                            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 10 }} className="h5"><span style={{ color: colors.blue[900] }}>Hits&nbsp;-&nbsp;</span>&nbsp;Planned Vs Actual&nbsp;&nbsp;</div>
                        </Box>
                        <Box width={'74%'}>
                            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 10 }} className="h5"><span style={{ color: colors.blue[900] }}>Hits &nbsp;-&nbsp;</span>&nbsp; Actual&nbsp;&nbsp;</div>
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <Rolling30DaysLineChartHitsComponent
                                showDataLabels={toggleDataLabel}
                                chartTitles={{ chartTitle: `${product_name} Hits`, seriesAname: 'Planned Hits', seriesBname: 'Actual Hits' }}
                            />
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <PieChartComponent
                                hitsRevenue='hits'
                                chartTitle={'By Team - Actual Hits'}
                                seriesData={teamWiseHitsSeriesData}
                                LablesData={teamWiseHitsSeriesLabels}
                                field={'team'}
                                height='auto'
                            />
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <PieChartComponent
                                hitsRevenue='hits'
                                chartTitle={'By Team Member - Actual Hits'}
                                seriesData={memberWiseHitsSeriesData}
                                LablesData={memberWiseHitsSeriesLabels}
                                field={'member'}
                                height='auto'
                            />
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <PieChartComponent
                                hitsRevenue='hits'
                                chartTitle={'By Platform - Actual Hits'}
                                seriesData={platformWiseHitsSeriesData}
                                LablesData={platformWiseHitsSeriesLabels}
                                field={'platform'}
                                height='auto'
                            />
                        </Box>
                    </Box>
                </Grid>
                <Box margin={1}>
                    <Box display="flex" width={'100%'} flexDirection="row" flexGrow={1} flexWrap="wrap" marginTop={1.5}>
                        <Box width={'24%'}>
                            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 10 }} className="h5"><span style={{ color: colors.blue[900] }}>Revenue&nbsp;-&nbsp;</span>&nbsp; Planned Vs Actual&nbsp;&nbsp;</div>
                        </Box>
                        <Box width={'74%'}>
                            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 10 }} className="h5"><span style={{ color: colors.blue[900] }}>Revenue&nbsp;-&nbsp;</span>&nbsp; Actual&nbsp;&nbsp;</div>
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <Rolling30DaysLineChartRevenueComponent
                                dateRangeObj={props.dateRange}
                                showDataLabels={toggleDataLabel}
                                chartTitles={{ chartTitle: `${product_name} Revenue`, seriesAname: 'Planned Revenue', seriesBname: 'Actual Revenue' }}
                            />
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <PieChartComponent
                                hitsRevenue='revenue'
                                chartTitle={'By Team - Actual Revenue'}
                                seriesData={teamWiseRevSeriesData}
                                LablesData={teamWiseRevSeriesLabels}
                                field={'team'}
                            />
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <PieChartComponent
                                hitsRevenue='revenue'
                                chartTitle={'By Team Member - Actual Revenue'}
                                seriesData={memberWiseRevSeriesData}
                                LablesData={memberWiseRevSeriesLabels}
                                field={'member'}
                                height='auto'
                            />
                        </Box>
                        <Box width={'24%'} borderRadius={5} boxShadow={2} margin={0.25}>
                            <PieChartComponent
                                hitsRevenue='revenue'
                                chartTitle={'By Platform - Actual Revenue'}
                                seriesData={platformWiseRevSeriesData}
                                LablesData={platformWiseRevSeriesLabels}
                                field={'platform'}
                            />
                        </Box>
                    </Box>
                </Box>
            </Tabpanel>

            <Tabpanel value={value} index={1}>
                <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin={0.5} width={'100%'}>
                    <Box margin={0.5} width={'50%'}>
                        <div style={{ textAlign: "left", fontWeight: 700, marginTop: 15, color: colors.teal[800] }} className="h5">Team Member Wise  - Hits</div>
                        <LineChartTeamMembersHitsComponent
                            hitsRevenue='hits'
                            field={'member'}
                            showDataLabels={toggleDataLabel}
                            chartTitles={{ chartTitle: `${product_name} Hits`, seriesAname: 'Planned Hits', seriesBname: 'Actual Hits' }}
                        />
                    </Box>
                    <Box margin={0.5} width={'50%'}>
                        <div style={{ textAlign: "left", fontWeight: 700, marginTop: 15, color: colors.teal[800] }} className="h5">Team Member Wise  - Revenue</div>
                        <LineChartTeamMembersRevenueComponent
                            hitsRevenue='revenue'
                            showDataLabels={toggleDataLabel}
                            chartTitles={{ chartTitle: `${product_name} Revenue`, seriesAname: 'Planned Revenue', seriesBname: 'Actual Revenue' }}
                            field={'member'}
                        />
                    </Box>
                </Box>
            </Tabpanel>

            <Tabpanel value={value} index={2}>
                <Box display="flex" flexDirection="row" justifyContent="space-evenly" margin={0.5} width={'100%'}>
                    <Box margin={0.5} width={'50%'}>
                        <div style={{ textAlign: "left", fontWeight: 700, marginTop: 15, color: colors.teal[800] }} className="h5">Platform Wise  - Hits</div>
                        <LineChartPlatformWiseHitsComponent
                            field={'platform'}
                            showDataLabels={toggleDataLabel}
                            chartTitles={{ chartTitle: `${product_name} Hits`, seriesAname: 'Planned Hits', seriesBname: 'Actual Hits' }}
                            height='auto'
                        />
                    </Box>
                    <Box margin={0.5} width={'50%'}>
                        <div style={{ textAlign: "left", fontWeight: 700, marginTop: 15, color: colors.teal[800] }} className="h5">Platform Wise  - Revenue</div>
                        <LineChartPlatformWiseRevenueComponent
                            field={'platform'}
                            showDataLabels={toggleDataLabel}
                            chartTitles={{ chartTitle: `${product_name} Revenue`, seriesAname: 'Planned Revenue', seriesBname: 'Actual Revenue' }}
                            height='auto'
                        />
                    </Box>
                </Box>
            </Tabpanel>
            <Tabpanel value={value} index={3}>
                <Box display='flex' flexDirection='row' justifyContent='space-evenly' margin={1} flexWrap='wrap' >
                    <Box margin={0.5} width={'48%'}>
                        <Box border={1} borderColor={colors.blue[200]} bgcolor={colors.blue[50]} borderRadius={3} my={1} p={1} >Subtotaled Hits</Box>
                        <LinechartTeamMembersSubtotaledByTeam PlanHitsValue={"Hits"} />
                    </Box>
                    <Box margin={0.5} width={'48%'}>
                        <Box border={1} borderColor={colors.deepOrange[200]} bgcolor={colors.deepOrange[50]} borderRadius={3} my={1} p={1} >Subtotaled Revenue</Box>
                        <LinechartTeamMembersSubtotaledByTeam PlanHitsValue={"Revenue"} />
                    </Box>
                </Box>
            </Tabpanel>

            {addActualsModalOpen ? <Box bgcolor={colors.blueGrey[50]} ><AddActualsModalComponent
                openFlag={addActualsModalOpen}
                handleModalClose={handleAddActualsModalClose}
            /></Box> : null}
        </>
    )
    function Tabpanel(props) {
        const { children, value, index } = props;
        return <>{value === index && <h1>{children}</h1>}</>;
    }
};

export default MonthlyDetailDataChartsComponent;
