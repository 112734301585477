import React, { useEffect, useState } from 'react'
import { Redirect, Route } from 'react-router';
import { Switch, useHistory } from 'react-router-dom';

import AppbarComponent from './components/Appbar.component'
import RegisterComponent from './components/register.component';
import LoginComponent from './components/login.component';
import QuarterlyDetailPage from './pages/qtr.detail.page';
import QuarterlySummaryPage from './pages/qtr.summary.page';
import AuthService from "./services/auth.service";
import PageNotFound from './pages/pagenotfound';
import MonthlyDetailPage from './pages/monthly.detail.page';
import StackedGraphs from './pages/stacked.graphs';

import "bootstrap/dist/css/bootstrap.min.css";
import './App.css';

const App = (props) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState();

  useEffect( () => {
    const setcurrentuser = async () => { setCurrentUser(await AuthService.getCurrentUser()) }
    setcurrentuser();

    return () => clearImmediate();
  }, []);

  return (
    <div className="App">
      <AppbarComponent {...props} history={history} currentUser={currentUser} setCurrentUser={setCurrentUser} />
      <Switch>
        <Route
          exact
          path={["", "/"]}
          render={() => (
            currentUser ? <Redirect {...props} currentUser={currentUser} to={"/reports"} /> : <Redirect to="/login" />
          )} />
        <Route
          exact
          path={["", "/"]}
          render={() => currentUser && history.push("/reports")} />

        {/* <Route
          exact
          path="/reports"
          render={(props) => (
            currentUser ? <Redirect to="/reports/MonthlyDetail" {...props} currentUser={currentUser} /> : <Redirect {...props} to="/login" />
          )} />
        <Route
          exact
          path="/reports/Rolling12Months"
          render={(props) => (
            currentUser ? <QuarterlySummaryPage {...props} currentUser={currentUser} /> : <Redirect {...props} to="/login" />
          )} />
        <Route
          exact
          path="/reports/QuarterlyDetail"
          render={(props) => (
            currentUser ? <QuarterlyDetailPage {...props} currentUser={currentUser} /> : <Redirect {...props} to="/login" />
          )} /> */}
        <Route
          exact
          path="/reports"
          render={(props) => (
            currentUser ? <MonthlyDetailPage {...props} currentUser={currentUser} /> : <Redirect {...props} to="/login" />
          )} />
        {/* <Route
          exact
          path="/reports/StackedBars"
          render={(props) => (
            currentUser ? <StackedGraphs {...props} currentUser={currentUser} /> : <Redirect {...props} to="/login" />
          )} /> */}
        <Route
          exact
          path="/login"
          render={() => (
            currentUser ? <Redirect {...props} currentUser={currentUser} to={"/reports"} />
              : <LoginComponent {...props} history={history} currentUser={currentUser} setCurrentUser={setCurrentUser} />
          )} />
        {/* <Route
          exact
          path="/register"
          component={RegisterComponent} /> */}
        <Route
          exact
          path="*"
          component={PageNotFound} />
      </Switch>
    </div>
  );
}

export default App;