import React, { useContext } from 'react';
import { SelectedDateRangeContext } from '../pages/monthly.detail.page'
import { DataGrid } from '@material-ui/data-grid';
import { Box, colors, LinearProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 125,
        marginTop: 0,
        marginBottom: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    MuiDataGrid: {
        '& .MuiDataGrid-cell': {
            color: colors.grey[700],
            fontSize: "0.75rem !important",
            margin: "0 !important",
        },
        '& .MuiDataGrid-columnHeader': {
            color: colors.blue[900],
            fontSize: "0.4rem !important",
            fontWeight: "500 !important",
            lineHeight: "1.1 !important",
            height: '75px !important',

        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: "0.75rem !important",
            fontWeight: '500 !important',
            lineHeight: '1.1 !important',
            whiteSpace: 'break-spaces !important',
        }
    },
    MuiDataGridRoot: {
        padding: '0 !important',
        margin: '0 !important',
    },
}));

const MonthlySummaryByDateOverallDataTable = () => {
    const classes = useStyles();

    const { plannedHits, actualHits, plannedRevenue, actualRevenue, PlnHitsLoading, ActHitsLoading, PlnRevLoading, ActRevLoading, colsPlnHits, colsActHits, colsPlnRev, colsActRev } = useContext(SelectedDateRangeContext);

    const [stateplannedHits] = plannedHits
    const [stateactualHits] = actualHits
    const [stateplannedRevenue] = plannedRevenue
    const [stateactualRevenue] = actualRevenue

    const [statePlnHitsLoading] = PlnHitsLoading
    const [stateActHitsLoading] = ActHitsLoading
    const [statePlnRevLoading] = PlnRevLoading
    const [stateActRevLoading] = ActRevLoading

    const [statecolsPlnHits] = colsPlnHits
    const [statecolsActHits] = colsActHits
    const [statecolsActRev] = colsActRev
    const [statecolsPlnRev] = colsPlnRev

    // console.log('*** MonthlySummaryByDateOverallDataTable *** CONTEXT ::: ', useContext(SelectedDateRangeContext))
    return (
        <Box margin={1}>
            <div style={{ textAlign: "left", fontWeight: 700, marginTop: 15, color: colors.purple[500] }} className="h5">Date Wise Summary Report</div>
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Planned Hits</span></div>
            {statePlnHitsLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={stateplannedHits}
                    columns={statecolsPlnHits}
                />
            }
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Actual Hits</span></div>
            {stateActHitsLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={stateactualHits}
                    columns={statecolsActHits}
                />
            }
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Planned Revenue</span></div>
            {statePlnRevLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={stateplannedRevenue}
                    columns={statecolsPlnRev}
                />
            }
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Actual Revenue</span></div>
            {stateActRevLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={stateactualRevenue}
                    columns={statecolsActRev}
                />
            }
        </Box>
    )
}

export default MonthlySummaryByDateOverallDataTable;
