import React, { useContext, useEffect, useState } from 'react'
import { SelectedDateRangeContext } from '../pages/monthly.detail.page';
import Chart from 'react-apexcharts';
import { Box, LinearProgress, colors } from '@material-ui/core';

const LinechartTeamMembersSubtotaledByTeam = (props) => {

  const { plnHitsTeamMembersSubtotaledByTeam, actHitsTeamMembersSubtotaledByTeam, plnRevTeamMembersSubtotaledByTeam, actRevTeamMembersSubtotaledByTeam, timePeriod } = useContext(SelectedDateRangeContext);

  const [FilteredChartDataPlanned, setFilteredChartDataPlanned] = useState([]);
  const [FilteredChartDataActual, setFilteredChartDataActual] = useState([]);

  useEffect(() => {
    if (props.PlanHitsValue === 'Hits') {
      setFilteredChartDataPlanned(plnHitsTeamMembersSubtotaledByTeam)
      let actHits = actHitsTeamMembersSubtotaledByTeam[0].map((dataRows, idx) => dataRows.map((data) => data))
      console.log("FilteredChartDataPlanned ::", actHits)
      // setFilteredChartDataActual(actHits.map((e) => e.map((f) => f)))
      setFilteredChartDataActual(actHits)
    }
    if (props.PlanHitsValue === 'Revenue') {
      setFilteredChartDataPlanned(plnRevTeamMembersSubtotaledByTeam)
      let actRevs = actRevTeamMembersSubtotaledByTeam[0].map((dataRows, idx) => dataRows.map((data) => data))
      console.log("FilteredChartDataActual ::", actRevs)

      // setFilteredChartDataActual(actRevs.map((e) => e.map((f) => f)))
      setFilteredChartDataActual(actRevs)
    }
  }, [
    props.PlanHitsValue,
    plnHitsTeamMembersSubtotaledByTeam,
    actHitsTeamMembersSubtotaledByTeam,
    plnRevTeamMembersSubtotaledByTeam,
    actRevTeamMembersSubtotaledByTeam])

  // console.log("FilteredChartDataPlanned ::", FilteredChartDataPlanned)
  // console.log("FilteredChartDataActual ::", FilteredChartDataActual)

  return (
    <>
      {!FilteredChartDataPlanned.length
        ? <div><LinearProgress color="primary" /></div >
        : <Box display="flex" width={'100%'} flexDirection="row" flexWrap="wrap" justifyContent="space-evenly" marginTop={1}>
          {FilteredChartDataActual && FilteredChartDataPlanned &&
            FilteredChartDataActual.length && FilteredChartDataPlanned[0].map((dataRow, idx) =>
              dataRow.map((data, i) => (
                <Box key={i} width={'48%'} borderRadius={5} boxShadow={2} margin={0.25}>
                  <Chart
                    height={225}
                    width={'100%'}
                    type={'line'}
                    options={{
                      theme: { mode: 'light', palette: 'palette2' },
                      chart: { zoom: { enabled: true } },
                      dataLabels: { enabled: props.showDataLabels },
                      stroke: { show: true, curve: 'smooth', lineCap: 'round', width: 1.5, dashArray: 0 },
                      title: {
                        text: `${data["team_member"].toString().toUpperCase()} (${data["team"].toString().toUpperCase()})`,
                        align: 'left',
                        style: { color: colors.deepOrange[500], fontSize: '12px', fontWeight: 700, textTransform: 'capitalize !important' }
                      },
                      grid: { show: false },
                      xaxis: {
                        show: false,
                        type: timePeriod[0] === 'day' ? 'datetime' : 'category',
                        labels: { fontSize: '0.125rem' },
                        axisTicks: { show: false },
                        categories: Object.keys(data).slice(4),
                      },
                      yaxis: { show: true, type: 'number', labels: { show: true, formatter: (val) => props.PlanHitsValue === 'Revenue' ? parseFloat(val).toFixed(2) : parseInt(val) } },
                    }}
                    series={[
                      { name: "Planned", data: Object.values(data).slice(4) },
                      { name: "Actual", data: Object.values(FilteredChartDataActual[idx][i] || 0).slice(4) },
                    ]}
                  /></Box>
              ))

            )
          }</Box>
      }
    </>
  )
}

export default LinechartTeamMembersSubtotaledByTeam