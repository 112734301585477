import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {MenuIcon, QueueSharp, PublishSharp, DonutSmallSharp} from '@material-ui/icons';
// import QueueIcon from '@material-ui/icons/Queue';
// import PublishIcon from '@material-ui/icons/Publish';
// import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import { Box, Button, Drawer, colors, AppBar, Toolbar, List, ListItem, ListItemIcon, ListItemText, makeStyles, Typography, IconButton } from '@material-ui/core/';
import ReactFileReader from 'react-file-reader';
import axios from 'axios';

import AddActualsModalComponent from './add-actuals-modal.component';

import AuthService from '../services/auth.service';

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

const useStyles = makeStyles((theme) => ({
  root: { flexGrow: 1 },
  menuButton: { marginRight: theme.spacing(2) },
  title: { display: 'flex', flexGrow: 1, justifyContent: 'flex-end' },
  MuiButton: { textTransform: 'capitalize !important', fontWeight: '200 !important' },
  list: { width: 250 },
  fullList: { width: 250 },
}));


export const SelectedDateRangeContext = React.createContext();


export default function AppbarComponent(props) {

  const classes = useStyles();
  const history = useHistory();

  const [user, setUser] = useState(null);
  const [addActualsModalOpen, setAddActualsModalOPen] = useState(false);
  const [drawerState, setDrawerState] = useState(false);

  const handleLogout = () => {
    AuthService.logout().then(async () => {
      localStorage.removeItem('user');
      setUser(null);
      props.setCurrentUser(null);
      history.push('/login');
    })
  };

  const handleFiles = (file) => {
    var reader = new FileReader();
    reader.readAsBinaryString(file[0]);
    reader.onload = function (e) {
      let options = {
        method: 'POST',
        url: `/api/v2/uploadplan`,
        Headers: { 'Content-Type': 'application/json, text/plain' },
        data: { data: reader.result },
      };
      axios.request(options)
        .then(async resp => console.log(`UploadPlan API Resp :: ${JSON.stringify(resp.data)}`))
        .catch(err => console.error(`Error from UploadPlan API :: ${err}`));
    };
  };

  const handleAddActualsModalClose = () => setAddActualsModalOPen(false);

  const toggleDrawer = () => {
    setDrawerState(!drawerState);
  };

  const list = () => (
    <div className={classes.fullList} role="presentation" >
      <List>
        {[
          { label: 'Rolling 12 Months', path: 'Rolling12Months' },
          { label: 'Quarterly Detail', path: 'QuarterlyDetail' },
          { label: 'Rolling 30 Days', path: 'MonthlyDetail' },
          /* { label: 'Stacked Bars', path: 'StackedBars' } */
        ].map((menuItem, index) =>
          <ListItem button key={index} onClick={() => props.history.push(`/reports/${menuItem.path}`)} >
            <ListItemIcon><DonutSmallSharp /></ListItemIcon>
            <ListItemText primary={menuItem.label} />
          </ListItem>)}
      </List>
    </div>
  );

  useEffect(() => { setUser(props.currentUser) }, [props.currentUser]);

  return (
    <div className={classes.root}>
      <AppBar position="sticky" /* position="fixed" */>
        <Toolbar variant="dense">
          {/* {user &&
            <IconButton
              onClick={() => toggleDrawer(drawerState)}
              edge="start"
              className={classes.menuButton}
              color="inherit"
            >
              <MenuIcon />
              <Drawer
                anchor={'left'}
                open={drawerState}
                onClose={() => toggleDrawer(drawerState)}
                color={colors.blueGrey[500]}>
                {list()}
              </Drawer>
            </IconButton>
          } */}
          <Typography variant="body2">
            <Link to="/reports" style={{ textDecoration: 'none', color: 'inherit' }}>
              <span style={{ fontWeight: 500, color: colors.grey[100], letterSpacing: 0, margin: 0, padding: 0 }}>SALES</span>&nbsp;
              <span style={{ fontWeight: 500, color: colors.deepOrange[300], letterSpacing: 2, margin: 0, padding: 0 }}>VIEW</span></Link>
          </Typography>
          {user &&
            <div className={classes.title}>
              {(user.id === 20171119 || user.id === 20171121 || user.id === 2021121) && <>
                <input type="file" accept="csv" style={{ display: 'none' }} />
                <ReactFileReader fileTypes={".csv"} multipleFiles={false} handleFiles={handleFiles}>
                  <Button startIcon={<PublishSharp color='primary' />} variant="contained" size="medium" color="default" style={{ marginRight: '4rem' }} >Upload Plan</Button>
                </ReactFileReader>
                <Button startIcon={<QueueSharp color='primary' />} variant="contained" size="medium" color="default" style={{ marginRight: '4rem' }} onClick={() => setAddActualsModalOPen(true)}>Add Actuals</Button>
              </>}
              <Button style={{ textTransform: 'capitalize', fontWeight: 200 }} color="inherit">Hi {props.currentUser.name}</Button>
              <Button style={{ textTransform: 'capitalize', fontWeight: 200 }} color="inherit" onClick={() => handleLogout()}>Logout</Button>
            </div>
          }
        </Toolbar>
      </AppBar>
      {addActualsModalOpen ?
        <Box bgcolor={colors.blueGrey[50]} >
          <AddActualsModalComponent openFlag={addActualsModalOpen} handleModalClose={handleAddActualsModalClose} />
        </Box> : null}
    </div>
  );
}
