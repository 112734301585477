import React, { useState, useContext } from 'react';
import { Box, colors, LinearProgress } from '@material-ui/core';
import Chart from 'react-apexcharts';

import { SelectedDateRangeContext } from '../pages/monthly.detail.page';

const LineChartPlatformWiseRevenueComponent = (props) => {

  const { revplatformChartAct, revplatformChartPlan, PlnHitsLoading, ActHitsLoading, PlnRevLoading, ActRevLoading, timePeriod } = useContext(SelectedDateRangeContext);

  const [field] = useState(props.field);
  const [chartTitles] = useState(props.chartTitles);
  const [ChartDataPlannedHitsSummary] = revplatformChartPlan
  const [ChartDataActualHitsSummary] = revplatformChartAct

  const [statePlnHitsLoading] = PlnHitsLoading
  const [stateActHitsLoading] = ActHitsLoading
  const [statePlnRevLoading] = PlnRevLoading
  const [stateActRevLoading] = ActRevLoading

  return (
    <>
      {statePlnHitsLoading && stateActHitsLoading && statePlnRevLoading && stateActRevLoading ? <div><LinearProgress color="primary" /></div > :
        <Box display="flex" width={'100%'} justifyContent="row" flexWrap="wrap" marginTop={1}>
          {ChartDataActualHitsSummary && ChartDataPlannedHitsSummary &&
            ChartDataActualHitsSummary.length > 0 && ChartDataPlannedHitsSummary.map((dataRow, idx) =>
              <Box key={idx} width={'48%'} borderRadius={5} boxShadow={2} margin={0.25}>
                <Chart
                  height={225}
                  width={'100%'}
                  type={'line'}
                  options={{
                    theme: { mode: 'light', palette: 'palette2' },
                    chart: { zoom: { enabled: true } },
                    dataLabels: { enabled: props.showDataLabels },
                    stroke: { show: true, curve: 'smooth', lineCap: 'round', width: 1.5, dashArray: 0 },
                    title: {
                      text: dataRow[field].toString().toUpperCase(),
                      align: 'left',
                      style: { color: colors.deepOrange[500], fontSize: '12px', fontWeight: 700, textTransform: 'capitalize !important' }
                    },
                    grid: { show: false },
                    xaxis: {
                      show: false,
                      type: timePeriod[0] === 'day' ? 'datetime' : 'category',
                      labels: { fontSize: '0.125rem' },
                      axisTicks: { show: false },
                      categories: Object.keys(ChartDataPlannedHitsSummary[0]).slice(3),
                    },
                    yaxis: { show: true, type: 'number', labels: { show: true, formatter: val => parseFloat(val).toFixed(2) } },
                  }}
                  series={[
                    { name: chartTitles.seriesAname, data: Object.values(dataRow).slice(3) },
                    { name: chartTitles.seriesBname, data: Object.values(ChartDataActualHitsSummary[idx]).slice(3) },
                  ]}
                /></Box>
            )
          }</Box>
      }
    </>
  )
};

export default LineChartPlatformWiseRevenueComponent;