import React, { useState, useEffect } from 'react'
import { Box, colors, Container, LinearProgress, makeStyles, TextField, MenuItem } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { format, endOfQuarter, startOfQuarter } from 'date-fns'
import Chart from 'react-apexcharts';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    MuiDataGrid: {
        '& .MuiDataGrid-cell': {
            color: colors.grey[700],
            fontSize: "0.75rem !important",
            margin: "0 !important",
        },
        '& .MuiDataGrid-columnHeader': {
            color: colors.blue[900],
            fontSize: "0.4rem !important",
            fontWeight: "500 !important",
            lineHeight: "1.1 !important",
            height: '75px !important',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: "0.75rem !important",
            fontWeight: '500 !important',
            lineHeight: '1.1 !important',
            whiteSpace: 'break-spaces !important',
        }
    },
    MuiDataGridRoot: {
        padding: '0 !important',
        margin: '0 !important',
    },
}));

const QuarterlyDetailPage = (props) => {
    const classes = useStyles();

    const [HitsByQuarterByWeekSummaryDataReady, setHitsByQuarterByWeekSummaryDataReady] = useState(false);
    const [HitsByQuarterByWeekDetailDataReady, setHitsByQuarterByWeekDetailDataReady] = useState(false);
    const [LinkedInPlannedHitsDataReady, setLinkedInPlannedHitsDataReady] = useState(false);
    const [LinkedInActualHitsDataReady, setLinkedInActualHitsDataReady] = useState(false);
    const [LinkedInPlannedRevenueDataReady, setLinkedInPlannedRevenueDataReady] = useState(false);
    const [LinkedInActualRevenueDataReady, setLinkedInActualRevenueDataReady] = useState(false);
    const [TwitterPlannedHitsDataReady, setTwitterPlannedHitsDataReady] = useState(false);
    const [TwitterActualHitsDataReady, setTwitterActualHitsDataReady] = useState(false);
    const [TwitterPlannedRevenueDataReady, setTwitterPlannedRevenueDataReady] = useState(false);
    const [TwitterActualRevenueDataReady, setTwitterActualRevenueDataReady] = useState(false);

    const [PlannedHitsByQuarterByWeekSummary, setPlannedHitsByQuarterByWeekSummary] = useState([]);
    const [PlannedRevenueByQuarterByWeekSummary, setPlannedRevenueByQuarterByWeekSummary] = useState([]);
    const [LinkedInPlannedHits, setLinkedInPlannedHits] = useState([]);
    const [LinkedInActualHits, setLinkedInActualHits] = useState([]);
    const [LinkedInPlannedRevenue, setLinkedInPlannedRevenue] = useState([]);
    const [LinkedInActualRevenue, setLinkedInActualRevenue] = useState([]);
    const [TwitterPlannedHits, setTwitterPlannedHits] = useState([]);
    const [TwitterActualHits, setTwitterActualHits] = useState([]);
    const [TwitterPlannedRevenue, setTwitterPlannedRevenue] = useState([]);
    const [TwitterActualRevenue, setTwitterActualRevenue] = useState([]);
    const [DetailColumns, setDetailColumns] = useState([]);
    const [HitsByQuarterByWeekSummaryColumns, setHitsByQuarterByWeekSummaryColumns] = useState([]);
    const [RevenueByQuarterByWeekSummaryColumns, setRevenueByQuarterByWeekSummaryColumns] = useState([]);

    const [products, ] = useState([
        { label: 'FilingSearch', value: 'fs' },
        { label: 'InverstorsSite', value: 'is' },
        { label: 'Candorium', value: 'cand' },
        // { label: 'Candorium App', value: 'canapp' },
        // { label: 'Candorium Web', value: 'canweb' },
    ]);
    const [product, setProduct] = useState(products[0].value)

    const prepareColumns = async (obj) => {
        let colsArray = [];
        Object.keys(obj).forEach((col, idx) => {
            let wd, hide, type;
            idx === 0 ? hide = true : hide = false;
            idx < 3 ? wd = 150 : wd = 90;
            idx < 3 ? type = 'text' : type = 'number';
            let c = {
                field: col, headerName: col.toUpperCase(), width: wd, hide: hide, type: type, sortable: false,
                valueFormatter: (params) => {
                    if (isNaN(params.value))
                        return params.value
                    else
                        return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(params.value)
                }
            };
            colsArray.push(c);
        });
        return colsArray;
    };

    useEffect(() => {
        const getQuarterlyData = async () => {
            let date = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 5, 30, 0);
            let startdate = startOfQuarter(date);
            let enddate = endOfQuarter(date);

            /* ***** ROLLING 12 MONTHS REPORT BY QUARTER ***** */
            /* HITS  PLANNED vs ACTUAL */
            let PlannedHitsByQuarterByWeekSummaryConfig = {
                method: 'POST', url: `/api/getPlannedHitsByQuarterByWeekSummary`, headers: { 'Content-Type': 'application/json' },
                data: { startdate: format(startdate, 'yyyy-MM-dd'), enddate: format(enddate, 'yyyy-MM-dd'), product_name: product },
            };

            await axios.request(PlannedHitsByQuarterByWeekSummaryConfig).then(async (Resp) => {
                if (Resp.data.length > 0) {
                    // console.log('getPlannedHitsByQuarterByWeekSummary :: ');
                    // console.log(Resp);
                    let indexed = Resp.data.map((row, idx) => ({ ...row, id: idx }))
                    setPlannedHitsByQuarterByWeekSummary(indexed);
                    setHitsByQuarterByWeekSummaryColumns(await prepareColumns(Resp.data[0]));
                }
            }).catch(err => {
                console.error(`Error From ${PlannedHitsByQuarterByWeekSummaryConfig.url} -- ${err}`);
            });

            /* REVENUE  PLANNED vs ACTUAL */
            let PlannedRevenueByQuarterByWeekSummaryConfig = {
                method: 'POST', url: `/api/getPlannedRevenueByQuarterByWeekSummary`, headers: { 'Content-Type': 'application/json' },
                data: { startdate: format(startdate, 'yyyy-MM-dd'), enddate: format(enddate, 'yyyy-MM-dd'), product_name: product },
            };

            await axios.request(PlannedRevenueByQuarterByWeekSummaryConfig).then(async Resp => {
                if (Resp.data.length > 0) {
                    let indexed = Resp.data.map((row, idx) => ({ ...row, id: idx }))
                    setPlannedRevenueByQuarterByWeekSummary(indexed);
                    setRevenueByQuarterByWeekSummaryColumns(await prepareColumns(Resp.data[0]));
                }
            }).catch(err => {
                console.error(`Error From ${PlannedRevenueByQuarterByWeekSummaryConfig.url} -- ${err}`);
            });

            /* ***** ROLLING 12 MONTHS REPORT BY QUARTER ***** */
            /* HITS  PLANNED vs ACTUAL */
            let PlannedHitsByQuarterByWeekDetailConfig = {
                method: 'POST', url: `/api/getPlannedHitsByQuarterByWeekDetail`, headers: { 'Content-Type': 'application/json' },
                data: { startdate: format(startdate, 'yyyy-MM-dd'), enddate: format(enddate, 'yyyy-MM-dd'), product_name: product },
            };

            await axios.request(PlannedHitsByQuarterByWeekDetailConfig).then(async (Resp) => {
                if (Resp.data.length > 0) {
                    let indexed = Resp.data.map((row, idx) => ({ ...row, id: idx }))
                    setLinkedInPlannedHits(indexed.filter(obj => obj.platform === 'linkedin'));
                    // console.log('getPlannedHitsByQuarterByWeekDetail :: ');
                    // console.log(Resp.data);
                    // console.log(Resp.data.filter(obj => obj.platform === 'linkedin'));
                    setTwitterPlannedHits(indexed.filter(obj => obj.platform === 'twitter'));
                    // console.log(Resp.data.filter(obj => obj.platform === 'twitter'));
                    setDetailColumns(await prepareColumns(Resp.data[0]));
                }
            }).catch(err => {
                console.error(`Error From ${PlannedHitsByQuarterByWeekDetailConfig.url} -- ${err}`);
            });

            let ActualHitsByQuarterByWeekDetailConfig = {
                method: 'POST', url: `/api/getActualHitsByQuarterByWeekDetail`, headers: { 'Content-Type': 'application/json' },
                data: { startdate: format(startdate, 'yyyy-MM-dd'), enddate: format(enddate, 'yyyy-MM-dd'), product_name: product },
            };

            await axios.request(ActualHitsByQuarterByWeekDetailConfig).then(async (Resp) => {
                if (Resp.data.length > 0) {
                    let indexed = Resp.data.map((row, idx) => ({ ...row, id: idx }))
                    setLinkedInActualHits(indexed.filter(obj => obj.platform === 'linkedin'));
                    // console.log('getActualHitsByQuarterByWeekDetail :: ');
                    // console.log(LinkedInActualHits);
                    setTwitterActualHits(indexed.filter(obj => obj.platform === 'twitter'));
                    setDetailColumns(await prepareColumns(Resp.data[0]));
                }
            }).catch(err => {
                console.error(`Error From ${ActualHitsByQuarterByWeekDetailConfig.url} -- ${err}`);
            });

            let PlannedRevenueByQuarterByWeekDetailConfig = {
                method: 'POST', url: `/api/getPlannedRevenueByQuarterByWeekDetail`, headers: { 'Content-Type': 'application/json' },
                data: { startdate: format(startdate, 'yyyy-MM-dd'), enddate: format(enddate, 'yyyy-MM-dd'), product_name: product },
            };

            await axios.request(PlannedRevenueByQuarterByWeekDetailConfig).then(async (Resp) => {
                if (Resp.data.length > 0) {
                    let indexed = Resp.data.map((row, idx) => ({ ...row, id: idx }))
                    setLinkedInPlannedRevenue(indexed.filter(obj => obj.platform === "linkedin"));
                    setTwitterPlannedRevenue(indexed.filter(obj => obj.platform === 'twitter'));
                    setDetailColumns(await prepareColumns(Resp.data[0]));
                }
            }).catch(err => {
                console.error(`Error From ${PlannedRevenueByQuarterByWeekDetailConfig.url} -- ${err}`);
            });

            let ActualRevenueByQuarterByWeekDetailConfig = {
                method: 'POST',
                url: `/api/getActualRevenueByQuarterByWeekDetail`,
                headers: { 'Content-Type': 'application/json' },
                data: {
                    startdate: format(startdate, 'yyyy-MM-dd'), enddate: format(enddate, 'yyyy-MM-dd'), product_name: product
                },
            };

            await axios.request(ActualRevenueByQuarterByWeekDetailConfig).then(async (Resp) => {
                if (Resp.data.length > 0) {
                    let indexed = Resp.data.map((row, idx) => ({ ...row, id: idx }))
                    setLinkedInActualRevenue(indexed.filter(obj => obj.platform === 'linkedin'));
                    setTwitterActualRevenue(indexed.filter(obj => obj.platform === 'twitter'));
                    setDetailColumns(await prepareColumns(Resp.data[0]));
                }
            }).catch(err => {
                console.error(`Error From ${ActualRevenueByQuarterByWeekDetailConfig.url} -- ${err}`);
            });
        };

        getQuarterlyData().then(() => {
            setHitsByQuarterByWeekDetailDataReady(true);
            setHitsByQuarterByWeekSummaryDataReady(true);
            setLinkedInPlannedHitsDataReady(true);
            setLinkedInActualHitsDataReady(true);
            setLinkedInPlannedRevenueDataReady(true);
            setLinkedInActualRevenueDataReady(true);
            setTwitterPlannedHitsDataReady(true);
            setTwitterActualHitsDataReady(true);
            setTwitterPlannedRevenueDataReady(true);
            setTwitterActualRevenueDataReady(true);
        });
    }, [product]);


    return (
        <div>
            <Box my={2}>
                <TextField
                    id="ProductsList"
                    select
                    variant='outlined'
                    size='small'
                    value={product}
                    onChange={(e) => setProduct(e.target.value)}
                    menuprops={{ PaperProps: { style: { maxHeight: 200 } } }}
                    style={{ width: 175 }}
                >
                    {products.length > 0 && products.map((pro, index) => (
                        <MenuItem key={index} value={pro.value} disabled={pro.value === 'is'} >{pro.label}</MenuItem>
                    ))}
                </TextField>
            </Box>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>Quarterly Hits By Week - </span>&nbsp;Planned Vs Actual&nbsp;&nbsp;</h5></div>
                {HitsByQuarterByWeekDetailDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        // key={HitsByQuarterByWeekSummary.id}
                        rows={PlannedHitsByQuarterByWeekSummary}
                        columns={HitsByQuarterByWeekSummaryColumns}
                    /> : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>Quarterly Revenue By Week - </span>&nbsp;Planned Vs Actual&nbsp;&nbsp;</h5></div>
                {HitsByQuarterByWeekSummaryDataReady ?
                    <>
                        <DataGrid
                            className={classes.MuiDataGrid}
                            disableColumnMenu={true}
                            disableColumnFilter
                            disableSelectionOnClick
                            isCellEditable={false}
                            hideFooter
                            autoHeight
                            density="compact"
                            key={PlannedRevenueByQuarterByWeekSummary.id}
                            rows={PlannedRevenueByQuarterByWeekSummary}
                            columns={RevenueByQuarterByWeekSummaryColumns}
                            pageSize={10}
                        />
                        <Box display="flex" width={'100%'} flexDirection="row" justifyContent="space-between" flexWrap="wrap" marginTop={1.5}>
                            {PlannedHitsByQuarterByWeekSummary && PlannedHitsByQuarterByWeekSummary.length > 0 ? PlannedHitsByQuarterByWeekSummary.map((dataRow, idx) =>
                                <Box key={idx} width={'49%'} boxShadow={2} mx={0.5}>
                                    <Chart height={250}
                                        options={{
                                            theme: { mode: 'light', palette: 'palette2' },
                                            type: 'line',
                                            xaxis: { show: true, labels: { show: true }, axisTicks: { show: false } },
                                            yaxis: { show: false },
                                            dataLabels: { enabled: false },
                                            stroke: {
                                                show: true,
                                                curve: 'smooth',
                                                lineCap: 'round',
                                                width: 1.5
                                            },
                                            grid: { show: false },
                                            title: { text: PlannedHitsByQuarterByWeekSummary[idx]['DESCRIPTION'].split(' ')[0].toString().toUpperCase(), align: 'left', style: { fontSize: '12px', fontWeight: 500, color: colors.deepOrange[900] } },
                                        }}
                                        series={[
                                            { name: PlannedHitsByQuarterByWeekSummary[idx]['DESCRIPTION'].toString().toLowerCase(), data: Object.values(dataRow).slice(4) },
                                            { name: PlannedRevenueByQuarterByWeekSummary[idx]['DESCRIPTION'].toString().toLowerCase(), data: Object.values(PlannedRevenueByQuarterByWeekSummary[idx]).slice(4) }
                                        ]}
                                    /></Box>
                            ) : ''}
                        </Box></>
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>LinkedIn Platform -  Planned Hits- </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {LinkedInPlannedHitsDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={LinkedInPlannedHits.id}
                        rows={LinkedInPlannedHits}
                        columns={DetailColumns}
                        loading={!LinkedInPlannedHitsDataReady}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>LinkedIn Platform - Actual Hits - </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {console.log('LinkedInActualHits :::: ', LinkedInActualHits)}
                {LinkedInActualHitsDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={LinkedInActualHits.id}
                        rows={LinkedInActualHits}
                        columns={DetailColumns}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>LinkedIn Platform -  Planned Revenue- </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {console.log('LinkedInPlannedRevenue :::::: ', LinkedInPlannedRevenue)}
                {LinkedInPlannedRevenueDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={LinkedInPlannedRevenue.id}
                        rows={LinkedInPlannedRevenue}
                        columns={DetailColumns}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>LinkedIn Platform -  Actual Revenue- </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {LinkedInActualRevenueDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={LinkedInActualRevenue.id}
                        rows={LinkedInActualRevenue}
                        columns={DetailColumns}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>Twitter Platform -  Planned Hits- </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {TwitterPlannedHitsDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={TwitterPlannedHits.id}
                        rows={TwitterPlannedHits}
                        columns={DetailColumns}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>Twitter Platform -  Actual Hits- </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {TwitterActualHitsDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={TwitterActualHits.id}
                        rows={TwitterActualHits}
                        columns={DetailColumns}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>Twitter Platform -  Planned Revenue- </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {TwitterPlannedRevenueDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={TwitterPlannedRevenue.id}
                        rows={TwitterPlannedRevenue}
                        columns={DetailColumns}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>

            <Container disableGutters style={{ marginTop: 30 }}>
                <div style={{ textAlign: 'left' }}><h5><span style={{ color: colors.deepPurple[900] }}>Twitter Platform -  Actual Revenue- </span>&nbsp;By Team members&nbsp;&nbsp;</h5></div>
                {TwitterActualRevenueDataReady ?
                    <DataGrid
                        className={classes.MuiDataGrid}
                        disableColumnMenu={true}
                        disableColumnFilter
                        disableSelectionOnClick
                        isCellEditable={false}
                        hideFooter
                        autoHeight
                        density="compact"
                        key={TwitterActualRevenue.id}
                        rows={TwitterActualRevenue}
                        columns={DetailColumns}
                    />
                    : <div><LinearProgress color="secondary" /></div>}
            </Container>
        </div>
    )
}

export default QuarterlyDetailPage;
