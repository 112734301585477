import React, { useState, useEffect } from 'react';
import { Box, makeStyles, Tab, Tabs, Paper, Grid, LinearProgress, MenuItem, TextField } from '@material-ui/core'
import { subDays, format, startOfDay, addYears } from 'date-fns';
import DatePicker from "react-datepicker";
import MonthlyDetailDataTableComponent from '../components/monthly.detail.datatable.component_new';
import MonthlyDetailDataChartsComponent from '../components/monthly.detail.datachart.component';
import axios from 'axios';

import "react-datepicker/dist/react-datepicker.css";
import {
    getDateWiseHitsRevenueSummary,
    getPlatformWiseHitsRevenue,
    getPlatformWiseTeamWiseHitsRevenue,
    getTeamMembersSubtotaledByTeamHitsRevenueSummary,
    getTeamMembersSubtotaledByTeamHitsRevenueSummaryNew,
    getTeamMemberWiseHitsRevenueSummary,
    getTeamWiseTeamMemberWiseHitsRevenueSummary,
    getTeamWiseHitsRevenueSummary,
    getPlannedHitsData, getActualHitsData, getPlannedRevenueData, getActualRevenueData
} from '../services/reports.service';



const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 150,
        textAlign: 'center !important',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    MuiDataGrid: {
        fontSize: '0.75rem !important'
    },
    ReactDatePicker: {
        margin: "0 !important",
        padding: "10px !important",
        width: "200px !important",
        textAlign: "center !important",
        border: "1px solid #DADADA",
        borderRadius: 5
    },
}));

export const SelectedDateRangeContext = React.createContext();

const MonthlyDetailPage = (props) => {
    const classes = useStyles();

    const [value, setValue] = useState(0);
    const [timePeriod, setTimePeriod] = useState('day')
    const [DisplayChart, setDisplayChart] = useState(false);
    const [dateRange, setDateRange] = useState([startOfDay(subDays(new Date(), 30)), startOfDay(new Date())]);
    const [StartDate, EndDate] = dateRange;
    const [dateRangeObj, setDateRangeObj] = useState({ startdate: startOfDay(new Date(StartDate)), enddate: startOfDay(new Date(EndDate)) });
    const [toggleDataLabel, setToggleDataLabel] = useState(false);

    /* -------------Date Wise Data------------- */
    const [plannedHits, setPlannedHits] = useState([]);
    const [actualHits, setActualHits] = useState([]);
    const [plannedRevenue, setPlannedRevenue] = useState([]);
    const [actualRevenue, setActualRevenue] = useState([]);

    const [colsPlnHits, setColsPlnHits] = useState([]);
    const [colsActHits, setColsActHits] = useState([]);
    const [colsPlnRev, setColsPlnRev] = useState([]);
    const [colsActRev, setColsActRev] = useState([]);

    /* -------------Platform Wise Data------------- */
    const [platformplannedHits, setPlatformPlannedHits] = useState([]);
    const [platformactualHits, setPlatformActualHits] = useState([]);
    const [platformplannedRevenue, setPlatformPlannedRevenue] = useState([]);
    const [platformactualRevenue, setPlatformActualRevenue] = useState([]);

    const [platformcolsPlnHits, setPlatformColsPlnHits] = useState([]);
    const [platformcolsActHits, setPlatformColsActHits] = useState([]);
    const [platformcolsPlnRev, setPlatformColsPlnRev] = useState([]);
    const [platformcolsActRev, setPlatformColsActRev] = useState([]);

    const [platformChartAxisHitsSummary, setPlatformChartAxisHitsSummary] = useState([]);
    const [platformChartDataPlannedHitsSummary, setPlatformChartDataPlannedHitsSummary] = useState([]);
    const [platformChartDataActualHitsSummary, setPlatFormChartDataActualHitsSummary] = useState([]);

    const [revplatformChartAxisHitsSummary, setRevPlatformChartAxisHitsSummary] = useState([]);
    const [revplatformChartDataPlannedHitsSummary, setRevPlatformChartDataPlannedHitsSummary] = useState([]);
    const [revplatformChartDataActualHitsSummary, setRevPlatformChartDataActualHitsSummary] = useState([]);

    /* -------------Team Wise Data------------- */
    const [teamplannedHits, setTeamPlannedHits] = useState([]);
    const [teamactualHits, setTeamActualHits] = useState([]);
    const [teamplannedRevenue, setTeamPlannedRevenue] = useState([]);
    const [teamactualRevenue, setTeamActualRevenue] = useState([]);

    const [teamcolsPlnHits, setTeamColsPlnHits] = useState([]);
    const [teamcolsActHits, setTeamColsActHits] = useState([]);
    const [teamcolsPlnRev, setTeamColsPlnRev] = useState([]);
    const [teamcolsActRev, setTeamColsActRev] = useState([]);

    /* -------------Member Wise Data------------- */
    const [memberplannedHits, setMemberPlannedHits] = useState([]);
    const [memberactualHits, setMemberActualHits] = useState([]);
    const [memberplannedRevenue, setMemberPlannedRevenue] = useState([]);
    const [memberactualRevenue, setMemberActualRevenue] = useState([]);

    const [membercolsPlnHits, setMemberColsPlnHits] = useState([]);
    const [membercolsActHits, setMemberColsActHits] = useState([]);
    const [membercolsPlnRev, setMemberColsPlnRev] = useState([]);
    const [membercolsActRev, setMemberColsActRev] = useState([]);

    const [hitChartAxisHitsSummary, setHitChartAxisHitsSummary] = useState([]);
    const [hitChartDataPlannedHitsSummary, setHitChartDataPlannedHitsSummary] = useState([]);
    const [hitChartDataActualHitsSummary, setHitChartDataActualHitsSummary] = useState([]);

    const [revChartAxisHitsSummary, setRevChartAxisHitsSummary] = useState([]);
    const [revChartDataPlannedHitsSummary, setRevChartDataPlannedHitsSummary] = useState([]);
    const [revChartDataActualHitsSummary, setRevChartDataActualHitsSummary] = useState([]);


    /* -------------Team Member Wise Subtotaled By Team------------- */
    const [plnHitsTeamMembersSubtotaledByTeam, setPlnHitsTeamMembersSubtotaledByTeam] = useState([]);
    const [actHitsTeamMembersSubtotaledByTeam, setActHitsTeamMembersSubtotaledByTeam] = useState([]);
    const [plnRevTeamMembersSubtotaledByTeam, setPlnRevTeamMembersSubtotaledByTeam] = useState([]);
    const [actRevTeamMembersSubtotaledByTeam, setActRevTeamMembersSubtotaledByTeam] = useState([]);

    /* -------------Rolling 30days------------- */
    const [rollChartAxisHitsSummary, setRollChartAxisHitsSummary] = useState([]);
    const [rollChartDataPlannedHitsSummary, setRollChartDataPlannedHitsSummary] = useState([]);
    const [rollChartDataActualHitsSummary, setRollChartDataActualHitsSummary] = useState([]);

    const [rollRevChartAxisHitsSummary, setrollRevChartAxisHitsSummary] = useState([]);
    const [rollRevChartDataPlannedHitsSummary, setrollRevChartDataPlannedHitsSummary] = useState([]);
    const [rollRevChartDataActualHitsSummary, setrollRevChartDataActualHitsSummary] = useState([]);

    /* -------------Pie Chart ------------- */
    const [teamWiseRevSeriesData, setTeamWiseRevSeriesData] = useState([]);
    const [teamWiseRevSeriesLabels, setTeamWiseRevSeriesLabels] = useState([]);
    const [memberWiseRevSeriesData, setMemberWiseRevSeriesData] = useState([]);
    const [memberWiseRevSeriesLabels, setMemberWiseRevSeriesLabels] = useState([]);
    const [platformWiseRevSeriesData, setPlatformWiseRevSeriesData] = useState([]);
    const [platformWiseRevSeriesLabels, setPlatformWiseRevSeriesLabels] = useState([]);

    const [teamWiseHitsSeriesData, setTeamWiseHitsSeriesData] = useState([]);
    const [teamWiseHitsSeriesLabels, setTeamWiseHitsSeriesLabels] = useState([]);
    const [memberWiseHitsSeriesData, setMemberWiseHitsSeriesData] = useState([]);
    const [memberWiseHitsSeriesLabels, setMemberWiseHitsSeriesLabels] = useState([]);
    const [platformWiseHitsSeriesData, setPlatformWiseHitsSeriesData] = useState([]);
    const [platformWiseHitsSeriesLabels, setPlatformWiseHitsSeriesLabels] = useState([]);

    const [PlnHitsLoading, setPlnHitsLoading] = useState(true);
    const [ActHitsLoading, setActHitsLoading] = useState(true);
    const [PlnRevLoading, setPlnRevLoading] = useState(true);
    const [ActRevLoading, setActRevLoading] = useState(true);

    const [newPlatformWiseTeamWiseHitsRevenueData, setNewPlatformWiseTeamWiseHitsRevenueData] = useState([])
    const [newTeamMembersSubtotaledByTeamHitsRevenueSummary, setNewTeamMembersSubtotaledByTeamHitsRevenueSummary] = useState([])
    const [newteamWiseTeamMemberWiseHitsRevenue, setTeamWiseTeamMemberWiseHitsRevenue] = useState([])


    const [plannedHitsByPlatformByTeam, setPlannedHitsByPlatformByTeam] = useState([]);
    const [actualHitsByPlatformByTeam, setActualHitsByPlatformByTeam] = useState([]);
    const [plannedRevenueByPlatformByTeam, setPlannedRevenueByPlatformByTeam] = useState([]);
    const [actualRevenueByPlatformByTeam, setActualRevenueByPlatformByTeam] = useState([]);


    const [products, setProducts] = useState([
        { label: 'FilingSearch', value: 'fs' },
        { label: 'InverstorsSite', value: 'is' },
        { label: 'Candorium', value: 'cand' },
        // { label: 'Candorium App', value: 'canapp' },
        // { label: 'Candorium Web', value: 'canweb' },
    ]);
    const [product, setProduct] = useState(products[0].value)

    const prepareColumns = async (objKeys, hitsRevenue) => {
        // console.log("Object Keys:::", objKeys);
        let colsArray = [];
        Object.keys(objKeys).forEach((col, idx) => {
            let hd, wd, hide, type, desc;
            const valueFormatter = (params) => {
                if (isNaN(params.value))
                    return params.value
                else
                    return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(params.value)
            };

            // idx <= 1 ? hide = true : hide = false;
            hitsRevenue === 'revenue' ? idx <= 2 ? wd = 70 : wd = 55 : idx <= 2 ? wd = 70 : wd = 55;
            idx < 1 ? hd = col.toUpperCase() : timePeriod === 'day' ? hd = new Date(col).getDate() : hd = col;
            idx < 0 ? type = 'text' : type = 'number';
            // if (col === "ROW TOTAL" || col === "row_total") {
            //     hd = "ROW TOTAL";
            //     wd = 80;
            //     desc = "ROW TOTAL"
            // } else {
            //     desc = col;
            // }
            let c = { field: col, headerName: hd, description: desc, width: timePeriod === 'day' ? wd : 65, hide: hide, type: type, sortable: false, headerClassName: classes.MuiDataGrid, valueFormatter };
            colsArray.push(c);
        });
        return colsArray;
    };

    const addSubTotalRow = async (data, subTotalIdx) => {
        return await data.reduce((prev = {}, curr = {}, idx) => {
            // console.log("ITEM @ ", idx, " ::: ", prev, curr);
            let row = Object.keys(curr).map((key, i) => {
                // console.log("NAME IDX ::: ", i, key)
                if (i <= subTotalIdx) {
                    prev[key] = 'Sub Total';
                } else if (i > subTotalIdx) {
                    let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
                    prev[key] = calc;
                } else {
                    return curr;
                }
            });
            return prev;
        }, {});

        // return result
    }

    const handleTabs = (e, val) => { setValue(val) };

    // const handleGetReport = () => {
    //     setDateRangeObj({ startdate: startOfDay(new Date(StartDate)), enddate: startOfDay(new Date(EndDate)) });
    // };

    const fetchData = async () => {
        // console.log('******   MAIN INTERNAL FUNCTION CALL ****');
        setPlnHitsLoading(true);
        setActHitsLoading(true);
        setPlnRevLoading(true);
        setActRevLoading(true);

        /* #################################### */
        /* ### NEW API ENDPOINTS WITHOUT DB SP ### */
        /* #################################### */
        // let platformWiseTeamWiseHitsRevenueData = await getPlatformWiseTeamWiseHitsRevenue(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), product);
        // console.log('platformWiseTeamWiseHitsRevenueData RESP ::: ', platformWiseTeamWiseHitsRevenueData.data.message);
        // setNewPlatformWiseTeamWiseHitsRevenueData(platformWiseTeamWiseHitsRevenueData.data.message)

        // let teamMembersSubtotaledByTeamHitsRevenueSummary = await getTeamMembersSubtotaledByTeamHitsRevenueSummary(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), timePeriod, product)
        // console.log('teamMembersSubtotaledByTeamHitsRevenueSummary RESP ::: ', teamMembersSubtotaledByTeamHitsRevenueSummary.data.message);


        // let teamMembersSubtotaledByTeamHitsRevenueSummaryNew = await getTeamMembersSubtotaledByTeamHitsRevenueSummaryNew(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), timePeriod, product)
        // console.log('teamMembersSubtotaledByTeamHitsRevenueSummaryNew RESP ::: ', teamMembersSubtotaledByTeamHitsRevenueSummaryNew.data.message);
        /* #################################### */


        let dateWiseHitsRevenueSummaryData = await getDateWiseHitsRevenueSummary(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), product, timePeriod);
        // console.log('dateWiseHitsRevenueSummaryData RESP ::: ', dateWiseHitsRevenueSummaryData.data.message);

        /* ***************************************************************** */
        /* ************************ Rolling 30 days ************************ */
        /* ***************************************************************** */
        setRollChartDataPlannedHitsSummary(Object.values(dateWiseHitsRevenueSummaryData.data.message[0].planned_hits).slice(1) || 0);
        setRollChartAxisHitsSummary(Object.keys(dateWiseHitsRevenueSummaryData.data.message[0].planned_hits).slice(1));
        setRollChartDataActualHitsSummary(Object.values(dateWiseHitsRevenueSummaryData.data.message[1].actual_hits).slice(1) || 0);
        setrollRevChartDataPlannedHitsSummary(Object.values(dateWiseHitsRevenueSummaryData.data.message[2].planned_revenue).slice(1) || 0);
        setrollRevChartAxisHitsSummary(Object.keys(dateWiseHitsRevenueSummaryData.data.message[2].planned_revenue).slice(1));
        setrollRevChartDataActualHitsSummary(Object.values(dateWiseHitsRevenueSummaryData.data.message[3].actual_revenue).slice(1) || 0);

        // let plnSummaryConfig = {
        //     method: 'POST', url: `/api/getPlannedHitsSummary`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let actSummaryConfig = {
        //     method: 'POST', url: `/api/getActualHitsSummary`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // let revplnSummaryConfig = {
        //     method: 'POST', url: `/api/getPlannedRevenueSummary`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let revactSummaryConfig = {
        //     method: 'POST', url: `/api/getActualRevenueSummary`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // await axios.request(plnSummaryConfig).then(async resp => {
        //     // console.log(plnSummaryConfig.url);
        //     // console.log(resp.data);
        //     if (resp.data.length > 0) {
        //         setRollChartDataPlannedHitsSummary(Object.values(resp.data[0]).slice(3) || 0);
        //         setRollChartAxisHitsSummary(Object.keys(resp.data[0]).slice(3));
        //     } else {
        //         setRollChartDataPlannedHitsSummary([]);
        //         setRollChartAxisHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.error(`${plnSummaryConfig.url} ERR :: `);
        //     console.error(err);
        //     setRollChartDataPlannedHitsSummary([]);
        //     setRollChartAxisHitsSummary([]);
        // });

        // await axios.request(actSummaryConfig).then(async resp => {
        //     // console.log(actSummaryConfig.url);
        //     // console.log(resp.data);
        //     if (resp.data.length > 0)
        //         setRollChartDataActualHitsSummary(Object.values(resp.data[0]).slice(3) || 0);
        //     else
        //         setRollChartDataActualHitsSummary([]);
        // }).catch(err => {
        //     console.error(`${actSummaryConfig.url} ERR :: `);
        //     console.error(err);
        //     setRollChartDataActualHitsSummary([]);
        // });

        // await axios.request(revplnSummaryConfig).then(async resp => {
        //     // console.log(plnSummaryConfig.url);
        //     // console.log(resp.data);
        //     if (resp.data.length > 0) {
        //         setrollRevChartDataPlannedHitsSummary(Object.values(resp.data[0]).slice(3) || 0);
        //         setrollRevChartAxisHitsSummary(Object.keys(resp.data[0]).slice(3));
        //     } else {
        //         setrollRevChartDataPlannedHitsSummary([]);
        //         setrollRevChartAxisHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.error(`${revplnSummaryConfig.url} ERR :: `);
        //     console.error(err);
        //     setrollRevChartDataPlannedHitsSummary([]);
        //     setrollRevChartAxisHitsSummary([]);
        // });

        // await axios.request(revactSummaryConfig).then(async resp => {
        //     // console.log(actSummaryConfig.url);
        //     // console.log(resp.data);
        //     if (resp.data.length > 0)
        //         setrollRevChartDataActualHitsSummary(Object.values(resp.data[0]).slice(3) || 0);
        //     else
        //         setrollRevChartDataActualHitsSummary([]);
        // }).catch(err => {
        //     console.error(`${revactSummaryConfig.url} ERR :: `);
        //     console.error(err);
        //     setrollRevChartDataActualHitsSummary([]);
        // });

        /* ************************************************************ */
        /* ************************ Date Wise ************************ */
        /* ************************************************************ */

        setPlannedHits([{ id: 0, ...dateWiseHitsRevenueSummaryData.data.message[0].planned_hits }]);
        setColsPlnHits(await prepareColumns(dateWiseHitsRevenueSummaryData.data.message[0].planned_hits), 'hits');
        setActualHits([{ id: 0, ...dateWiseHitsRevenueSummaryData.data.message[1].actual_hits }]);
        setColsActHits(await prepareColumns(dateWiseHitsRevenueSummaryData.data.message[1].actual_hits), 'hits');
        setPlannedRevenue([{ id: 0, ...dateWiseHitsRevenueSummaryData.data.message[2].planned_revenue }])
        setColsPlnRev(await prepareColumns(dateWiseHitsRevenueSummaryData.data.message[2].planned_revenue), 'revenue');
        setActualRevenue([{ id: 0, ...dateWiseHitsRevenueSummaryData.data.message[3].actual_revenue }])
        setColsActRev(await prepareColumns(dateWiseHitsRevenueSummaryData.data.message[3].actual_revenue), 'revenue');

        // let plnHitConfig = {
        //     method: 'POST', url: `/api/getPlannedHitsMonthlySummaryByDate`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let actHitConfig = {
        //     method: 'POST', url: `/api/getActualHitsMonthlySummaryByDate`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let plnRevConfig = {
        //     method: 'POST', url: `/api/getPlannedRevenueMonthlySummaryByDate`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let actRevConfig = {
        //     method: 'POST', url: `/api/getActualRevenueMonthlySummaryByDate`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // await axios.request(plnHitConfig).then(async plnHitsResp => {
        //     // console.log('Planned HITS ::::',plnHitsResp.data);
        //     if (plnHitsResp.data.length > 0) {
        //         setPlannedHits(plnHitsResp.data);
        //         setColsPlnHits(await prepareColumns(plnHitsResp.data[0]), 'hits');
        //     } else {
        //         setPlannedHits([]);
        //         setColsPlnHits([]);
        //     }
        // }).catch(err => {
        //     console.log(plnHitConfig.url, ' Error :::');
        //     console.log(err);
        //     setPlannedHits([]);
        //     setColsPlnHits([]);
        // });

        // await axios.request(actHitConfig).then(async actHitsResp => {
        //     if (actHitsResp.data.length > 0) {
        //         setActualHits(actHitsResp.data);
        //         setColsActHits(await prepareColumns(actHitsResp.data[0]), 'hits');
        //     } else {
        //         setActualHits([]);
        //         setColsActHits([]);
        //     }
        // }).catch(err => {
        //     console.log(actHitConfig.url, ' Error :::: ');
        //     console.log(err);
        //     setActualHits([]);
        //     setColsActHits([]);
        // });

        // await axios.request(plnRevConfig).then(async plnRevResp => {
        //     if (plnRevResp.data.length > 0) {
        //         setPlannedRevenue(plnRevResp.data);
        //         setColsPlnRev(await prepareColumns(plnRevResp.data[0]), 'revenue');
        //     } else {
        //         setPlannedRevenue([]);
        //         setColsPlnRev([]);
        //     }
        // }).catch(err => {
        //     console.log(plnRevConfig.url, ' Error :::: ');
        //     console.log(err);
        //     setPlannedRevenue([]);
        //     setColsPlnRev([]);
        // });

        // await axios.request(actRevConfig).then(async actRevResp => {
        //     if (actRevResp.data.length > 0) {
        //         setActualRevenue(actRevResp.data);
        //         setColsActRev(await prepareColumns(actRevResp.data[0]), 'revenue');
        //     } else {
        //         setActualRevenue([]);
        //         setColsActRev([]);
        //     }
        // }).catch(err => {
        //     console.log(actRevConfig.url, 'Error ::::: ');
        //     console.log(err);
        //     setActualRevenue([]);
        //     setColsActRev([]);
        // });

        /* **************************************************************** */
        /* ************************ PlatForm wise ************************ */
        /* **************************************************************** */

        let platformWiseHitsRevenueData = await getPlatformWiseHitsRevenue(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), product, timePeriod);
        // console.log('platformWiseHitsRevenueData RESP ::: ', platformWiseHitsRevenueData.data.message);

        // setPlatformPlannedHits(platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.planned_hits })))
        let platformPlnHitsOrgData = platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.planned_hits }))
        setPlatformPlannedHits([...platformPlnHitsOrgData, await addSubTotalRow(platformPlnHitsOrgData, 1)])
        setPlatformColsPlnHits(await prepareColumns({ "platform": "", ...platformWiseHitsRevenueData.data.message[0].planned_hits }), 'hits');

        let platformActHitsOrgData = platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.actual_hits }))
        setPlatformActualHits([...platformActHitsOrgData, await addSubTotalRow(platformActHitsOrgData, 1)])
        setPlatformColsActHits(await prepareColumns({ "platform": "", ...platformWiseHitsRevenueData.data.message[0].actual_hits }), 'hits');

        let platformPlnRevOrgData = platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.planned_revenue }))
        setPlatformPlannedRevenue([...platformPlnRevOrgData, await addSubTotalRow(platformPlnRevOrgData, 1)])
        setPlatformColsPlnRev(await prepareColumns({ "platform": "", ...platformWiseHitsRevenueData.data.message[0].planned_revenue }), 'revenue');

        let platformActRevOrgData = platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.actual_revenue }))
        setPlatformActualRevenue([...platformActRevOrgData, await addSubTotalRow(platformActRevOrgData, 1)])
        setPlatformColsActRev(await prepareColumns({ "platform": "", ...platformWiseHitsRevenueData.data.message[0].actual_revenue }), 'revenue');


        /////--Platform Chart--////
        setPlatformChartAxisHitsSummary(Object.keys(await platformWiseHitsRevenueData.data.message[0].planned_hits || []));
        setPlatformChartDataPlannedHitsSummary(Object.values(await platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.planned_hits }))));
        setPlatFormChartDataActualHitsSummary(Object.values(await platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.actual_hits }))));

        setRevPlatformChartAxisHitsSummary(Object.keys(await platformWiseHitsRevenueData.data.message[0].planned_revenue || []));
        setRevPlatformChartDataPlannedHitsSummary(Object.values(await platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.planned_revenue }))));
        setRevPlatformChartDataActualHitsSummary(Object.values(await platformWiseHitsRevenueData.data.message.map((r, idx) => ({ "id": idx, 'platform': r.platform, ...r.actual_revenue }))));


        ////////--Pie Chart--////////////
        setPlatformWiseHitsSeriesData(Object.values(await platformWiseHitsRevenueData.data.message.map(x => parseFloat(x['actual_hits']['ROW TOTAL']))))
        setPlatformWiseHitsSeriesLabels(Object.values(await platformWiseHitsRevenueData.data.message.map(x => x["platform"])));

        setPlatformWiseRevSeriesData(Object.values(await platformWiseHitsRevenueData.data.message.map(x => parseFloat(x['actual_revenue']['ROW TOTAL']))));
        setPlatformWiseRevSeriesLabels(Object.values(await platformWiseHitsRevenueData.data.message.map(x => x["platform"])));


        // let platformplnHitConfig = {
        //     method: 'POST', url: `/api/getPlannedHitsMonthlySummaryByPlatform`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let platformactHitConfig = {
        //     method: 'POST', url: `/api/getActualHitsMonthlySummaryByPlatform`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let platformplnRevConfig = {
        //     method: 'POST', url: `/api/getPlannedRevenueMonthlySummaryByPlatform`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let platformactRevConfig = {
        //     method: 'POST', url: `/api/getActualRevenueMonthlySummaryByPlatform`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // await axios.request(platformplnHitConfig).then(async platformplnHitsResp => {
        //     if (platformplnHitsResp.data.length > 0) {
        //         setPlatformColsPlnHits(await prepareColumns(platformplnHitsResp.data[0]), 'hits');

        //         let orgData = platformplnHitsResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setPlatformPlannedHits([...orgData, subTotals])

        //         // CHART
        //         setPlatformChartDataPlannedHitsSummary(Object.values(platformplnHitsResp.data));
        //         setPlatformChartAxisHitsSummary(Object.keys(await platformplnHitsResp.data[0]));
        //     } else {
        //         setPlatformPlannedHits([]);
        //         setPlatformColsPlnHits([]);
        //         setPlatformChartDataPlannedHitsSummary([]);
        //         setPlatformChartAxisHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(platformplnHitConfig.url, 'Error ::::: ');
        //     console.log(err);
        //     setPlatformPlannedHits([]);
        //     setPlatformColsPlnHits([]);
        //     setPlatformChartDataPlannedHitsSummary([]);
        //     setPlatformChartAxisHitsSummary([]);
        // });

        // await axios.request(platformactHitConfig).then(async platformactHitsResp => {
        //     if (platformactHitsResp.data.length > 0) {

        //         setPlatformWiseHitsSeriesData(Object.values(await platformactHitsResp.data.map(x => parseFloat(x['mtd']))))
        //         setPlatformWiseHitsSeriesLabels(Object.values(await platformactHitsResp.data.map(x => x["platform"])))

        //         setPlatformColsActHits(await prepareColumns(platformactHitsResp.data[0]), 'hits');
        //         setPlatFormChartDataActualHitsSummary(Object.values(platformactHitsResp.data));

        //         let orgData = platformactHitsResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setPlatformActualHits([...orgData, subTotals])

        //     } else {
        //         setPlatformActualHits([]);
        //         setPlatformColsActHits([]);
        //         setPlatFormChartDataActualHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(platformactHitConfig.url, 'Error ::::: ');
        //     console.log(err);
        //     setPlatformActualHits([]);
        //     setPlatformColsActHits([]);
        //     setPlatFormChartDataActualHitsSummary([]);
        // });

        // await axios.request(platformplnRevConfig).then(async platformplnRevResp => {
        //     if (platformplnRevResp.data.length > 0) {
        //         setPlatformColsPlnRev(await prepareColumns(platformplnRevResp.data[0]), 'revenue');

        //         let orgData = platformplnRevResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setPlatformPlannedRevenue([...orgData, subTotals])

        //         setRevPlatformChartDataPlannedHitsSummary(Object.values(platformplnRevResp.data));
        //         setRevPlatformChartAxisHitsSummary(Object.keys(await platformplnRevResp.data[0]));
        //     } else {
        //         setPlatformPlannedRevenue([]);
        //         setPlatformColsPlnRev([]);
        //         setRevPlatformChartDataPlannedHitsSummary([]);
        //         setRevPlatformChartAxisHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(platformplnRevConfig.url, 'Table ERRRrrrrrrrr ========= ');
        //     console.log(err);
        //     setPlatformPlannedRevenue([]);
        //     setPlatformColsPlnRev([]);
        //     setRevPlatformChartDataPlannedHitsSummary([]);
        //     setRevPlatformChartAxisHitsSummary([]);
        // });

        // await axios.request(platformactRevConfig).then(async platformactRevResp => {
        //     if (platformactRevResp.data.length > 0) {
        //         setPlatformWiseRevSeriesData(Object.values(await platformactRevResp.data.map(x => parseFloat(x['mtd']))));
        //         setPlatformWiseRevSeriesLabels(Object.values(await platformactRevResp.data.map(x => x["platform"])));
        //         setPlatformColsActRev(await prepareColumns(platformactRevResp.data[0]), 'revenue');
        //         setRevPlatformChartDataActualHitsSummary(Object.values(platformactRevResp.data));

        //         let orgData = platformactRevResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setPlatformActualRevenue([...orgData, subTotals])

        //     } else {
        //         setPlatformActualRevenue([]);
        //         setPlatformColsActRev([]);
        //         setRevPlatformChartDataActualHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(platformactRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setPlatformActualRevenue([]);
        //     setPlatformColsActRev([]);
        //     setRevPlatformChartDataActualHitsSummary([]);
        // });

        // /* ************************************************************* */
        // /* ************************ Team Wise ************************ */
        // /* ************************************************************* */

        let teamWiseHitsRevenueSummaryData = await getTeamWiseHitsRevenueSummary(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), product, timePeriod);
        // console.log('teamWiseHitsRevenueSummaryData RESP ::: ', teamWiseHitsRevenueSummaryData.data.message);
        // setTeamPlannedHits(teamWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'team': r.team, ...r.planned_hits })))

        let teamPlnHitsOrgData = teamWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'team': r.team, ...r.planned_hits }))
        setTeamPlannedHits([...teamPlnHitsOrgData, await addSubTotalRow(teamPlnHitsOrgData, 1)])
        setTeamColsPlnHits(await prepareColumns({ "team": "", ...teamWiseHitsRevenueSummaryData.data.message[0].planned_hits }), 'hits');

        let teamActHitsOrgData = teamWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'team': r.team, ...r.actual_hits }))
        setTeamActualHits([...teamActHitsOrgData, await addSubTotalRow(teamActHitsOrgData, 1)])
        setTeamColsActHits(await prepareColumns({ "team": "", ...teamWiseHitsRevenueSummaryData.data.message[0].actual_hits }), 'hits');

        let teamPlnRevOrgData = teamWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'team': r.team, ...r.planned_revenue }))
        setTeamPlannedRevenue([...teamPlnRevOrgData, await addSubTotalRow(teamPlnRevOrgData, 1)])
        setTeamColsPlnRev(await prepareColumns({ "team": "", ...teamWiseHitsRevenueSummaryData.data.message[0].planned_revenue }), 'revenue');

        let teamActRevOrgData = teamWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'team': r.team, ...r.actual_revenue }))
        setTeamActualRevenue([...teamActRevOrgData, await addSubTotalRow(teamActRevOrgData, 1)])
        setTeamColsActRev(await prepareColumns({ "team": "", ...teamWiseHitsRevenueSummaryData.data.message[0].actual_revenue }), 'revenue');


        ////////--Pie Chart--////////////
        setTeamWiseHitsSeriesData(Object.values(await teamWiseHitsRevenueSummaryData.data.message.map(x => parseFloat(x['actual_hits']['ROW TOTAL']))))
        setTeamWiseHitsSeriesLabels(Object.values(await teamWiseHitsRevenueSummaryData.data.message.map(x => x["team"])));

        setTeamWiseRevSeriesData(Object.values(await teamWiseHitsRevenueSummaryData.data.message.map(x => parseFloat(x['actual_revenue']['ROW TOTAL']))));
        setTeamWiseRevSeriesLabels(Object.values(await teamWiseHitsRevenueSummaryData.data.message.map(x => x["team"])));

        // console.log("Team Planned Hits :::", teamWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'team': r.team, ...r.planned_hits })));

        // let teamplnHitConfig = {
        //     method: 'POST', url: `/api/getPlannedHitsMonthlySummaryByTeam`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let teamactHitConfig = {
        //     method: 'POST', url: `/api/getActualHitsMonthlySummaryByTeam`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let teamplnRevConfig = {
        //     method: 'POST', url: `/api/getPlannedRevenueMonthlySummaryByTeam`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let teamactRevConfig = {
        //     method: 'POST', url: `/api/getActualRevenueMonthlySummaryByTeam`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // await axios.request(teamplnHitConfig).then(async teamplnHitsResp => {
        //     if (teamplnHitsResp.data.length > 0) {
        //         setTeamColsPlnHits(await prepareColumns(teamplnHitsResp.data[0]), 'hits');
        //         // setTeamPlannedHits(teamplnHitsResp.data);

        //         let orgData = teamplnHitsResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setTeamPlannedHits([...orgData, subTotals])

        //     } else {
        //         setTeamPlannedHits([]);
        //         setTeamColsPlnHits([]);
        //     }
        // }).catch(err => {
        //     console.log(teamplnHitConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setTeamPlannedHits([]);
        //     setTeamColsPlnHits([]);
        // });

        // await axios.request(teamactHitConfig).then(async teamactHitsResp => {
        //     if (teamactHitsResp.data.length > 0) {
        //         setTeamWiseHitsSeriesData(Object.values(await teamactHitsResp.data.map(x => parseFloat(x['mtd']))))
        //         setTeamWiseHitsSeriesLabels(Object.values(await teamactHitsResp.data.map(x => x["team"])))
        //         setTeamColsActHits(await prepareColumns(teamactHitsResp.data[0]), 'hits');

        //         let orgData = teamactHitsResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i === 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setTeamActualHits([...orgData, subTotals])
        //     } else {
        //         setTeamActualHits([]);
        //         setTeamColsActHits([]);
        //     }
        // }).catch(err => {
        //     console.log(teamactHitConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setTeamActualHits([]);
        //     setTeamColsActHits([]);
        // });

        // await axios.request(teamplnRevConfig).then(async teamplnRevResp => {
        //     if (teamplnRevResp.data.length > 0) {
        //         setTeamColsPlnRev(await prepareColumns(teamplnRevResp.data[0]), 'revenue');

        //         let orgData = teamplnRevResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setTeamPlannedRevenue([...orgData, subTotals])

        //     } else {
        //         setTeamPlannedRevenue([]);
        //         setTeamColsPlnRev([]);
        //     }
        // }).catch(err => {
        //     console.log(teamplnRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setTeamPlannedRevenue([]);
        //     setTeamColsPlnRev([]);
        // });

        // await axios.request(teamactRevConfig).then(async teamactRevResp => {
        //     if (teamactRevResp.data.length > 0) {
        //         setTeamWiseRevSeriesData(Object.values(await teamactRevResp.data.map(x => parseFloat(x['mtd']))));
        //         setTeamWiseRevSeriesLabels(Object.values(await teamactRevResp.data.map(x => x["team"])));
        //         setTeamColsActRev(await prepareColumns(teamactRevResp.data[0]), 'revenue');

        //         let orgData = teamactRevResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setTeamActualRevenue([...orgData, subTotals])

        //     } else {
        //         setTeamActualRevenue([]);
        //         setTeamColsActRev([]);
        //     }
        // }).catch(err => {
        //     console.log(teamactRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setTeamActualRevenue([]);
        //     setTeamColsActRev([]);
        // });

        /* ********************************************************************** */
        /* ************************ Team Member Wise ************************ */
        /* ********************************************************************** */
        let teamMemberWiseHitsRevenueSummaryData = await getTeamMemberWiseHitsRevenueSummary(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), product, timePeriod);

        let memberPlnHitsOrgData = teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.planned_hits }))
        setMemberPlannedHits([...memberPlnHitsOrgData, await addSubTotalRow(memberPlnHitsOrgData, 1)])
        setMemberColsPlnHits(await prepareColumns({ "member": "", ...teamMemberWiseHitsRevenueSummaryData.data.message[0].planned_hits }), 'hits');

        let memberActHitsOrgData = teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.actual_hits }))
        setMemberActualHits([...memberActHitsOrgData, await addSubTotalRow(memberActHitsOrgData, 1)])
        setMemberColsActHits(await prepareColumns({ "member": "", ...teamMemberWiseHitsRevenueSummaryData.data.message[0].actual_hits }), 'hits');

        let memeberPlnRevOrgData = teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.planned_revenue }))
        setMemberPlannedRevenue([...memeberPlnRevOrgData, await addSubTotalRow(memeberPlnRevOrgData, 1)])
        setMemberColsPlnRev(await prepareColumns({ "member": "", ...teamMemberWiseHitsRevenueSummaryData.data.message[0].planned_revenue }), 'revenue');

        let memberActRevOrgData = teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.actual_revenue }))
        setMemberActualRevenue([...memberActRevOrgData, await addSubTotalRow(memberActRevOrgData, 1)])
        setMemberColsActRev(await prepareColumns({ "member": "", ...teamMemberWiseHitsRevenueSummaryData.data.message[0].actual_revenue }), 'revenue');

        /////--Member Chart--////
        setHitChartAxisHitsSummary(Object.keys(await teamMemberWiseHitsRevenueSummaryData.data.message[0].planned_hits || []).slice(1));
        setHitChartDataPlannedHitsSummary(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.planned_hits }))).slice(1));
        setHitChartDataActualHitsSummary(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.actual_hits }))).slice(1));

        setRevChartAxisHitsSummary(Object.keys(await teamMemberWiseHitsRevenueSummaryData.data.message[0].planned_revenue || []).slice(1));
        setRevChartDataPlannedHitsSummary(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.planned_revenue }))).slice(1));
        setRevChartDataActualHitsSummary(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'member': r.team_member_name, ...r.actual_revenue }))).slice(1));

        ////////--Pie Chart--////////////
        setMemberWiseHitsSeriesData(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map(x => parseFloat(x['actual_hits']['ROW TOTAL']))))
        setMemberWiseHitsSeriesLabels(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map(x => x["team_member_name"])));

        setMemberWiseRevSeriesData(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map(x => parseFloat(x['actual_revenue']['ROW TOTAL']))));
        setMemberWiseRevSeriesLabels(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map(x => x["team_member_name"])));


        // setMemberWiseHitsSeriesData(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map(x => parseFloat(x['mtd']))))
        // setMemberWiseHitsSeriesLabels(Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map(x => x["member"])))


        // console.log("setHitChartDataPlannedHitsSummary NEW :::", Object.values(await teamMemberWiseHitsRevenueSummaryData.data.message.map((r, idx) => ({ "id": idx, 'team_member_name': r.team_member_name, ...r.planned_hits }))));

        // let memberplnHitConfig = {
        //     method: 'POST', url: `/api/getPlannedHitsMonthlySummaryByTeamMember`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let memberactHitConfig = {
        //     method: 'POST', url: `/api/getActualHitsMonthlySummaryByTeamMember`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let memberplnRevConfig = {
        //     method: 'POST', url: `/api/getPlannedRevenueMonthlySummaryByTeamMember`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // let memberactRevConfig = {
        //     method: 'POST', url: `/api/getActualRevenueMonthlySummaryByTeamMember`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // await axios.request(memberplnHitConfig).then(async memberplnHitsResp => {
        //     if (memberplnHitsResp.data.length > 0) {
        //         setMemberColsPlnHits(await prepareColumns(memberplnHitsResp.data[0]), 'hits');

        //         let orgData = memberplnHitsResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setMemberPlannedHits([...orgData, subTotals])

        //         /////--Member Chart--////
        //         setHitChartDataPlannedHitsSummary(Object.values(memberplnHitsResp.data));
        //         console.log("setHitChartDataPlannedHitsSummary :::", Object.values(memberplnHitsResp.data));
        //         setHitChartAxisHitsSummary(Object.keys(await memberplnHitsResp.data[0]));
        //     } else {
        //         setMemberPlannedHits([]);
        //         setMemberColsPlnHits([]);
        //         /////--Member Chart--////
        //         setHitChartDataPlannedHitsSummary([]);
        //         setHitChartAxisHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(memberplnHitConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setMemberPlannedHits([]);
        //     setMemberColsPlnHits([]);
        //     setHitChartDataPlannedHitsSummary([]);
        //     setHitChartAxisHitsSummary([]);
        // });

        // await axios.request(memberactHitConfig).then(async memberactHitsResp => {
        //     if (memberactHitsResp.data.length > 0) {
        //         setMemberWiseHitsSeriesData(Object.values(await memberactHitsResp.data.map(x => parseFloat(x['mtd']))))
        //         setMemberWiseHitsSeriesLabels(Object.values(await memberactHitsResp.data.map(x => x["member"])))
        //         setMemberColsActHits(await prepareColumns(memberactHitsResp.data[0]), 'hits');

        //         let orgData = memberactHitsResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setMemberActualHits([...orgData, subTotals])
        //         /////--Member Chart--////
        //         setHitChartDataActualHitsSummary(Object.values(memberactHitsResp.data));
        //     } else {
        //         setMemberActualHits([]);
        //         setMemberColsActHits([]);
        //         /////--Member Chart--////
        //         setHitChartDataActualHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(memberactHitConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setMemberActualHits([]);
        //     setMemberColsActHits([]);
        //     setHitChartDataActualHitsSummary([]);
        // });

        // await axios.request(memberplnRevConfig).then(async memberplnRevResp => {
        //     if (memberplnRevResp.data.length > 0) {
        //         setMemberColsPlnRev(await prepareColumns(memberplnRevResp.data[0]), 'revenue');

        //         let orgData = memberplnRevResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setMemberPlannedRevenue([...orgData, subTotals])

        //         /////--Member Chart--////
        //         setRevChartDataPlannedHitsSummary(Object.values(memberplnRevResp.data));
        //         setRevChartAxisHitsSummary(Object.keys(await memberplnRevResp.data[0]));
        //     } else {
        //         setMemberPlannedRevenue([]);
        //         setMemberColsPlnRev([]);
        //         /////--Member Chart--////
        //         setRevChartDataPlannedHitsSummary([]);
        //         setRevChartAxisHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(memberplnRevConfig.url, 'Error ::::: ');
        //     console.log(err);
        //     setMemberPlannedRevenue([]);
        //     setMemberColsPlnRev([]);
        //     setRevChartDataPlannedHitsSummary([]);
        //     setRevChartAxisHitsSummary([]);
        // });

        // await axios.request(memberactRevConfig).then(async memberactRevResp => {
        //     setMemberWiseRevSeriesData(Object.values(await memberactRevResp.data.map(x => parseFloat(x['mtd']))));
        //     setMemberWiseRevSeriesLabels(Object.values(await memberactRevResp.data.map(x => x["member"])));

        //     if (memberactRevResp.data.length > 0) {
        //         setMemberColsActRev(await prepareColumns(memberactRevResp.data[0]), 'revenue');

        //         let orgData = memberactRevResp.data
        //         let subTotals;
        //         subTotals = await orgData.reduce((prev = {}, curr = {}, idx) => {
        //             // console.log("ITEM @ ", idx, " ::: ", prev, curr);
        //             let row = Object.keys(curr).map((key, i) => {
        //                 // console.log("NAME IDX ::: ", i, key)
        //                 if (i <= 2) {
        //                     prev[key] = "Sub Total";
        //                 } else if (i > 2) {
        //                     let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
        //                     prev[key] = calc;
        //                 } else {
        //                     return curr;
        //                 }
        //             });
        //             return prev;
        //         }, {});
        //         setMemberActualRevenue([...orgData, subTotals])
        //         /////--Member Chart--////
        //         setRevChartDataActualHitsSummary(Object.values(memberactRevResp.data));
        //     } else {
        //         setMemberActualRevenue([]);
        //         setMemberColsActRev([]);
        //         /////--Member Chart--////
        //         setRevChartDataActualHitsSummary([]);
        //     }
        // }).catch(err => {
        //     console.log(memberactRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        //     setMemberActualRevenue([]);
        //     setMemberColsActRev([]);
        //     setRevChartDataActualHitsSummary([]);
        // });


        /* ************************************************************************************** */
        /* ************************ Team Member Subtotaled By Team ************************ */
        /* ************************************************************************************** */

        let teamWiseTeamMemberWiseHitsRevenueSummary = await getTeamWiseTeamMemberWiseHitsRevenueSummary(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), product, timePeriod)
        // console.log('getTeamWiseTeamMemberWiseHitsRevenueSummary RESP ::: ', teamWiseTeamMemberWiseHitsRevenueSummary.data.message);

        let teams = new Set(teamWiseTeamMemberWiseHitsRevenueSummary.data.message.map(data => data['team_name']));
        teams = Array.from(teams);
        let teamMbrs = new Set(teamWiseTeamMemberWiseHitsRevenueSummary.data.message.map(data => data['team_member']));
        teamMbrs = Array.from(teamMbrs);
        let plnHitsData = [];
        teams.forEach(teamName => {
            let res = teamWiseTeamMemberWiseHitsRevenueSummary.data.message.filter(row => row.team_name === teamName).map((row, idx) =>
                ({ id: idx, team: row.team, team_member: row.team_member, ...row.planned_hits }));
            plnHitsData.push(res)
        });
        // console.log('pln Hits FILTERED DATA ::::: ', plnHitsData)
        setPlnHitsTeamMembersSubtotaledByTeam(plnHitsData);
        let actHitsData = [];
        teams.forEach(teamName => {
            let res = teamWiseTeamMemberWiseHitsRevenueSummary.data.message.filter(row => row.team_name === teamName).map((row, idx) =>
                ({ id: idx, team: row.team, team_member: row.team_member, ...row.actual_hits }));
            actHitsData.push(res)
        });
        // console.log('act Hits FILTERED DATA ::::: ', actHitsData)
        setActHitsTeamMembersSubtotaledByTeam(actHitsData);
        let plnRevData = [];
        teams.forEach(teamName => {
            let res = teamWiseTeamMemberWiseHitsRevenueSummary.data.message.filter(row => row.team_name === teamName).map((row, idx) =>
                ({ id: idx, team: row.team, team_member: row.team_member, ...row.planned_revenue }));
            plnRevData.push(res)
        });
        // console.log('pln Rev FILTERED DATA ::::: ', plnRevData)
        setPlnRevTeamMembersSubtotaledByTeam(plnRevData);
        let actRevData = [];
        teams.forEach(teamName => {
            let res = teamWiseTeamMemberWiseHitsRevenueSummary.data.message.filter(row => row.team_name === teamName).map((row, idx) =>
                ({ id: idx, team: row.team, team_member: row.team_member, ...row.actual_revenue }));
            actRevData.push(res)
        });
        // console.log('act Rev FILTERED DATA ::::: ', actRevData)
        setActRevTeamMembersSubtotaledByTeam(actRevData);

        // let teamMembersSubtotaledByTeamHitsRevenueSummaryNew = await getTeamMembersSubtotaledByTeamHitsRevenueSummaryNew(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), timePeriod, product)
        // console.log('teamMembersSubtotaledByTeamHitsRevenueSummaryNew RESP ::: ', teamMembersSubtotaledByTeamHitsRevenueSummaryNew.data.message);

        // let teams = new Set(teamMembersSubtotaledByTeamHitsRevenueSummaryNew.data.message.map(data => data['team']));
        // teams = Array.from(teams);


        // let planHitsSubTotal = teamMembersSubtotaledByTeamHitsRevenueSummaryNew.data.message.map((r, idx) => ({ "id": idx, "team": r.team, "team_member_name": r.team_member, ...r.planned_hits }))
        // let actualHitsSubTotal = teamMembersSubtotaledByTeamHitsRevenueSummaryNew.data.message.map((r, idx) => ({ "id": idx, "team": r.team, "team_member_name": r.team_member, ...r.actual_hits }))
        // let planrevenueSubTotal = teamMembersSubtotaledByTeamHitsRevenueSummaryNew.data.message.map((r, idx) => ({ "id": idx, "team": r.team, "team_member_name": r.team_member, ...r.planned_revenue }))
        // let actualRevenueSubTotal = teamMembersSubtotaledByTeamHitsRevenueSummaryNew.data.message.map((r, idx) => ({ "id": idx, "team": r.team, "team_member_name": r.team_member, ...r.actual_revenue }))

        // // console.log("Teams ::", teams);
        // // console.log("planHitsSubTotal ::", planHitsSubTotal);
        // // console.log("actualHitsSubTotal ::", actualHitsSubTotal);
        // // console.log("planrevenueSubTotal ::", planrevenueSubTotal);
        // // console.log("actualHitsSubTotal ::", actualHitsSubTotal);

        // let filteredTableData = [];
        // teams.forEach(teamName => {
        //     let res;
        //     res = planHitsSubTotal.filter(row => row.team === teamName);
        //     filteredTableData.push(res)
        //     // console.log('Filtered RES :: ', res);
        // })

        // console.log("filteredTableData", filteredTableData);
        // setPlnHitsTeamMembersSubtotaledByTeam(filteredTableData)


        /* *** PLANNED HITS *** */
        // let plnHitsTeamMembersSubtotaledByTeamConfig = {
        //     method: 'POST', url: `/api/get-planned-hits-by-teammembers-subtotaled-by-team`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // await axios.request(plnHitsTeamMembersSubtotaledByTeamConfig).then(async resp => {
        //     let orgData = resp.data.message;
        //     let teams = new Set(resp.data.message.map(data => data['team']));
        //     teams = Array.from(teams);
        //     let teamMbrs = new Set(orgData.map(data => data['team_member_name']));
        //     teamMbrs = Array.from(teamMbrs);
        //     let filteredTableData = [];
        //     teams.forEach(teamName => {
        //         let res;
        //         res = orgData.filter(row => row.team === teamName);
        //         filteredTableData.push(res)
        //         // console.log('Filtered RES :: ', res);
        //     })
        //     // console.log('FINAL FILTERED DATA ::::: ', filteredTableData)
        //     setPlnHitsTeamMembersSubtotaledByTeam(filteredTableData);
        // }).catch(err => {
        //     // console.log(memberactRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        // });

        // /* *** ACTUAL HITS *** */
        // let actHitsTeamMembersSubtotaledByTeamConfig = {
        //     method: 'POST', url: `/api/get-actual-hits-by-teammembers-subtotaled-by-team`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // await axios.request(actHitsTeamMembersSubtotaledByTeamConfig).then(async resp => {
        //     let orgData = resp.data.message;
        //     let teams = new Set(resp.data.message.map(data => data['team']));
        //     teams = Array.from(teams);
        //     let teamMbrs = new Set(orgData.map(data => data['team_member_name']));
        //     teamMbrs = Array.from(teamMbrs);
        //     let filteredTableData = [];
        //     teams.forEach(teamName => {
        //         let res;
        //         res = orgData.filter(row => row.team === teamName);
        //         filteredTableData.push(res)
        //         // console.log('Filtered RES :: ', res);
        //     })
        //     // console.log('FINAL FILTERED DATA ::::: ', filteredTableData)

        //     setActHitsTeamMembersSubtotaledByTeam(filteredTableData);
        // }).catch(err => {
        //     // console.log(memberactRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        // });

        // /* *** PLANNED REVENUE *** */
        // let plnRevTeamMembersSubtotaledByTeamConfig = {
        //     method: 'POST', url: `/api/get-planned-revenue-by-teammembers-subtotaled-by-team`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };
        // await axios.request(plnRevTeamMembersSubtotaledByTeamConfig).then(async resp => {
        //     let orgData = resp.data.message;
        //     let teams = new Set(resp.data.message.map(data => data['team']));
        //     teams = Array.from(teams);
        //     let teamMbrs = new Set(orgData.map(data => data['team_member_name']));
        //     teamMbrs = Array.from(teamMbrs);
        //     let filteredTableData = [];
        //     teams.forEach(teamName => {
        //         let res;
        //         res = orgData.filter(row => row.team === teamName);
        //         filteredTableData.push(res)
        //         // console.log('Filtered RES :: ', res);
        //     })
        //     // console.log('FINAL FILTERED DATA ::::: ', filteredTableData)

        //     setPlnRevTeamMembersSubtotaledByTeam(filteredTableData);
        // }).catch(err => {
        //     // console.log(memberactRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        // });

        // /* *** ACTUAL REVENUE *** */
        // let actRevTeamMembersSubtotaledByTeamConfig = {
        //     method: 'POST', url: `/api/get-actual-revenue-by-teammembers-subtotaled-by-team`, headers: { 'Content-Type': 'application/json' }, data: {
        //         startdate: format(StartDate, "yyyy-MM-dd"),
        //         enddate: format(EndDate, "yyyy-MM-dd"),
        //         reportby: timePeriod,
        //         product_name: product
        //     }
        // };

        // await axios.request(actRevTeamMembersSubtotaledByTeamConfig).then(async resp => {
        //     let orgData = resp.data.message;
        //     let teams = new Set(resp.data.message.map(data => data['team']));
        //     teams = Array.from(teams);
        //     let teamMbrs = new Set(orgData.map(data => data['team_member_name']));
        //     teamMbrs = Array.from(teamMbrs);
        //     let filteredTableData = [];
        //     teams.forEach(teamName => {
        //         let res;
        //         res = orgData.filter(row => row.team === teamName);
        //         filteredTableData.push(res)
        //         // console.log('Filtered RES :: ', res);
        //     })
        //     // console.log('FINAL FILTERED DATA ::::: ', filteredTableData)

        //     setActRevTeamMembersSubtotaledByTeam(filteredTableData);
        // }).catch(err => {
        //     // console.log(memberactRevConfig.url, ' Error ::::: ');
        //     console.log(err);
        // });


        // setPlannedHitsByPlatformByTeam(await getPlannedHitsData(dateRangeObj, timePeriod, product))
        // setActualHitsByPlatformByTeam(await getActualHitsData(dateRangeObj, timePeriod, product))
        // setPlannedRevenueByPlatformByTeam(await getPlannedRevenueData(dateRangeObj, timePeriod, product))
        // setActualRevenueByPlatformByTeam(await getActualRevenueData(dateRangeObj, timePeriod, product))

        let platformWiseTeamWiseHitsRevenueData = await getPlatformWiseTeamWiseHitsRevenue(format(StartDate, "yyyy-MM-dd"), format(EndDate, "yyyy-MM-dd"), product, timePeriod);
        // console.log('platformWiseTeamWiseHitsRevenueData RESP ::: ', platformWiseTeamWiseHitsRevenueData.data.message);
        setNewPlatformWiseTeamWiseHitsRevenueData(platformWiseTeamWiseHitsRevenueData.data.message)

        // console.log("LinkedIn planned Hits", platformWiseTeamWiseHitsRevenueData.data.message['planned_hit'].filter((r) => r.platform === 'linkedin' && r.team === 'editors' && Object.keys(r).length > 4));

    };

    // useEffect(() => {
    //     if (product === 'cand')
    //         setDateRangeObj({ startdate: startOfDay(new Date('2022-04-01')), enddate: startOfDay(new Date(EndDate)) });
    //     else
    //         setDateRangeObj({ startdate: startOfDay(new Date(StartDate)), enddate: startOfDay(new Date(EndDate)) });
    // }, [EndDate, DisplayChart, toggleDataLabel, product]);


    useEffect(() => {
        // if (dateRangeObj.enddate > dateRangeObj.startdate) {
        //     console.log('Calling API ::: ', dateRangeObj)
        EndDate &&
            fetchData().then(() => {
                setPlnHitsLoading(false);
                setActHitsLoading(false);
                setPlnRevLoading(false);
                setActRevLoading(false);
            });
        // } else {
        //     console.log('Not Calling API ::: ', dateRangeObj)
        // }
        return () => clearImmediate();
    }, [EndDate, timePeriod, product]);

    var myCurrentDate = StartDate;
    var myFutureDate = new Date(myCurrentDate);
    myFutureDate.setDate(myFutureDate.getDate() + 90);

    return (
        <Box width={'100%'} padding={1} >
            <Paper square>
                <Box width="100%" display='flex' flexDirection='row' justifyContent='space-between' alignItems="center">
                    <Box width="30%">
                        <Tabs value={value} onChange={handleTabs} variant="standard" indicatorColor="secondary" textColor="primary">
                            <Tab label="Display Charts" />
                            <Tab label="Display Reports" />
                        </Tabs>
                    </Box>
                    <Box width="40%" alignItems="center">
                        <Box width="100%" display="flex" justifyContent="space-evenly" alignItems="center">
                            <Box>
                                <TextField
                                    id="ProductsList"
                                    select
                                    variant='outlined'
                                    size='small'
                                    value={product}
                                    // helperText="Select a Product"
                                    // label="Product"
                                    // InputLabelProps={{ shrink: true }}
                                    onChange={(e) => setProduct(e.target.value)}
                                    menuprops={{ PaperProps: { style: { maxHeight: 200 } } }}
                                    style={{ width: 175 }}
                                >
                                    {products.length > 0 && products.map((pro, index) => (
                                        <MenuItem key={index} value={pro.value} disabled={pro.value === 'is'} >{pro.label}</MenuItem>
                                    ))}
                                </TextField>
                            </Box>
                            <Box>
                                <TextField
                                    id="ReportBy"
                                    select
                                    variant='outlined'
                                    size='small'
                                    value={timePeriod}
                                    // helperText="View Reports By"
                                    // label="Report By"
                                    // InputLabelProps={{ shrink: true }}
                                    menuprops={{ PaperProps: { style: { maxHeight: 200 } } }}
                                    style={{ width: 110 }}
                                    onChange={(e) => setTimePeriod(e.target.value)}>
                                    <MenuItem value={'day'} >Day</MenuItem>
                                    <MenuItem value={'week'} >Week</MenuItem>
                                    <MenuItem value={'month'}>Month</MenuItem>
                                    <MenuItem value={'quarter'}>Quarter</MenuItem>
                                    <MenuItem value={'year'}>Year</MenuItem>
                                </TextField>
                            </Box>
                            <Box>
                                {/* <FormControl className={classes.formControl}> */}
                                <DatePicker
                                    id="ReactDatePicker"
                                    className={classes.ReactDatePicker}
                                    dateFormat="MMM dd, yy"
                                    excludeTimes={true}
                                    selectsRange={true}
                                    monthsShown={2}
                                    showMonthDropdown
                                    showYearDropdown
                                    // withPortal
                                    fixedHeight
                                    minDate={product === 'fs' ? new Date("2021-04-01") : new Date("2022-04-01")}
                                    maxDate={addYears(new Date(), 1)}
                                    // minDate={new Date("2020-04-01")}
                                    // maxDate={myFutureDate} // to limit to future 90 days date based on start date selection
                                    // maxDate={new Date()} // to Limit to current Date
                                    startDate={StartDate}
                                    endDate={EndDate}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                />
                                {/* </FormControl> */}
                            </Box>
                            {/* <Box display="flex" justifyContent="flex-start" alignItems="center" width={'15%'} margin={0.25}>
                                <FormControl className={classes.formControl} >
                                    <Button variant="contained" size="small" color="secondary" onClick={(e) => handleGetReport(e)}><SearchOutlined />&nbsp;Get Report</Button>
                                </FormControl>
                            </Box> */}
                        </Box>
                    </Box>
                    <Box width="30%" alignItems="center"></Box>
                </Box>
            </Paper>

            <Tabpanel value={value} index={0}>
                <SelectedDateRangeContext.Provider value={{
                    dateRangeObj: [dateRangeObj, setDateRangeObj],
                    timePeriod: [timePeriod, setTimePeriod],
                    plannedHits: [plannedHits, setPlannedHits],
                    actualHits: [actualHits, setActualHits],
                    plannedRevenue: [plannedRevenue, setPlannedRevenue], actualRevenue: [actualRevenue, setActualRevenue],
                    PlnHitsLoading: [PlnHitsLoading, setPlnHitsLoading], ActHitsLoading: [ActHitsLoading, setActHitsLoading],
                    PlnRevLoading: [PlnRevLoading, setPlnRevLoading], ActRevLoading: [ActRevLoading, setActRevLoading],
                    hitchartAxixHit: [hitChartAxisHitsSummary, setHitChartAxisHitsSummary], hitchartDataPlan: [hitChartDataPlannedHitsSummary, setHitChartDataPlannedHitsSummary],
                    hitchartDataAct: [hitChartDataActualHitsSummary, setHitChartDataActualHitsSummary],
                    revchartAxixHit: [revChartAxisHitsSummary, setRevChartAxisHitsSummary], revchartDataPlan: [revChartDataPlannedHitsSummary, setRevChartDataPlannedHitsSummary],
                    revchartDataAct: [revChartDataActualHitsSummary, setRevChartDataActualHitsSummary],
                    platformChartAxis: [platformChartAxisHitsSummary, setPlatformChartAxisHitsSummary], platformChartPlan: [platformChartDataPlannedHitsSummary, setPlatformChartDataPlannedHitsSummary],
                    platformChartAct: [platformChartDataActualHitsSummary, setPlatFormChartDataActualHitsSummary],
                    revplatformChartAxis: [revplatformChartAxisHitsSummary, setRevPlatformChartAxisHitsSummary], revplatformChartPlan: [revplatformChartDataPlannedHitsSummary, setRevPlatformChartDataPlannedHitsSummary],
                    revplatformChartAct: [revplatformChartDataActualHitsSummary, setRevPlatformChartDataActualHitsSummary],
                    hitrollAxis: [rollChartAxisHitsSummary, setRollChartAxisHitsSummary],
                    hitrollPlan: [rollChartDataPlannedHitsSummary, setRollChartDataPlannedHitsSummary], hitrollAct: [rollChartDataActualHitsSummary, setRollChartDataActualHitsSummary],
                    revrollAxis: [rollRevChartAxisHitsSummary, setrollRevChartAxisHitsSummary],
                    revrollPlan: [rollRevChartDataPlannedHitsSummary, setrollRevChartDataPlannedHitsSummary], revrollAct: [rollRevChartDataActualHitsSummary, setrollRevChartDataActualHitsSummary],
                    plnHitsTeamMembersSubtotaledByTeam: [plnHitsTeamMembersSubtotaledByTeam, setPlnHitsTeamMembersSubtotaledByTeam],
                    actHitsTeamMembersSubtotaledByTeam: [actHitsTeamMembersSubtotaledByTeam, setActHitsTeamMembersSubtotaledByTeam],
                    plnRevTeamMembersSubtotaledByTeam: [plnRevTeamMembersSubtotaledByTeam, setPlnRevTeamMembersSubtotaledByTeam],
                    actRevTeamMembersSubtotaledByTeam: [actRevTeamMembersSubtotaledByTeam, setActRevTeamMembersSubtotaledByTeam],
                    product: [products.filter(o => o.value === product).map(o => o.label), setProduct],
                    teamWiseRevSeriesData, teamWiseRevSeriesLabels,
                    teamWiseHitsSeriesData, teamWiseHitsSeriesLabels,
                    memberWiseRevSeriesData, memberWiseRevSeriesLabels,
                    memberWiseHitsSeriesData, memberWiseHitsSeriesLabels,
                    platformWiseRevSeriesData, platformWiseRevSeriesLabels,
                    platformWiseHitsSeriesData, platformWiseHitsSeriesLabels,
                }}>
                    {PlnHitsLoading && PlnRevLoading && ActHitsLoading && ActRevLoading
                        ? <LinearProgress />
                        : <MonthlyDetailDataChartsComponent displayChart={true} label="Chart" toggleDataLabel={DisplayChart} />
                    }
                </SelectedDateRangeContext.Provider>
            </Tabpanel>
            <Tabpanel value={value} index={1}>
                <Grid item xs={12}>
                    <Box display="flex" my={1} mx={1}>
                        <Grid item md={12}>
                            <SelectedDateRangeContext.Provider value={{
                                dateRangeObj: [dateRangeObj, setDateRangeObj],
                                timePeriod: [timePeriod, setTimePeriod],
                                plannedHits: [plannedHits, setPlannedHits], actualHits: [actualHits, setActualHits],
                                plannedRevenue: [plannedRevenue, setPlannedRevenue], actualRevenue: [actualRevenue, setActualRevenue],
                                colsPlnHits: [colsPlnHits, setColsPlnHits], colsActHits: [colsActHits, setColsActHits],
                                colsPlnRev: [colsPlnRev, setColsPlnRev], colsActRev: [colsActRev, setColsActRev],
                                PlnHitsLoading: [PlnHitsLoading, setPlnHitsLoading], ActHitsLoading: [ActHitsLoading, setActHitsLoading], PlnRevLoading: [PlnRevLoading, setPlnRevLoading], ActRevLoading: [ActRevLoading, setActRevLoading],
                                platformplannedHits: [platformplannedHits, setPlatformPlannedHits], platformactualHits: [platformactualHits, setPlatformActualHits],
                                platformplannedRevenue: [platformplannedRevenue, setPlatformPlannedRevenue], platformactualRevenue: [platformactualRevenue, setPlatformActualRevenue],
                                platformcolsPlnHits: [platformcolsPlnHits, setPlatformColsPlnHits], platformcolsActHits: [platformcolsActHits, setPlatformColsActHits],
                                platformcolsPlnRev: [platformcolsPlnRev, setPlatformColsPlnRev], platformcolsActRev: [platformcolsActRev, setPlatformColsActRev],
                                teamplannedHits: [teamplannedHits, setTeamPlannedHits], teamactualHits: [teamactualHits, setTeamActualHits],
                                teamplannedRevenue: [teamplannedRevenue, setTeamPlannedRevenue], teamactualRevenue: [teamactualRevenue, setTeamActualRevenue],
                                teamcolsPlnHits: [teamcolsPlnHits, setTeamColsPlnHits], teamcolsActHits: [teamcolsActHits, setTeamColsActHits],
                                teamcolsPlnRev: [teamcolsPlnRev, setTeamColsPlnRev], teamcolsActRev: [teamcolsActRev, setTeamColsActRev],
                                memberplannedHits: [memberplannedHits, setMemberPlannedHits], memberactualHits: [memberactualHits, setMemberActualHits],
                                memberplannedRevenue: [memberplannedRevenue, setMemberPlannedRevenue], memberactualRevenue: [memberactualRevenue, setMemberActualRevenue],
                                membercolsPlnHits: [membercolsPlnHits, setMemberColsPlnHits], membercolsActHits: [membercolsActHits, setMemberColsActHits],
                                membercolsPlnRev: [membercolsPlnRev, setMemberColsPlnRev], membercolsActRev: [membercolsActRev, setMemberColsActRev],
                                plnHitsTeamMembersSubtotaledByTeam: [plnHitsTeamMembersSubtotaledByTeam, setPlnHitsTeamMembersSubtotaledByTeam],
                                actHitsTeamMembersSubtotaledByTeam: [actHitsTeamMembersSubtotaledByTeam, setActHitsTeamMembersSubtotaledByTeam],
                                plnRevTeamMembersSubtotaledByTeam: [plnRevTeamMembersSubtotaledByTeam, setPlnRevTeamMembersSubtotaledByTeam],
                                actRevTeamMembersSubtotaledByTeam: [actRevTeamMembersSubtotaledByTeam, setActRevTeamMembersSubtotaledByTeam],
                                product: [product, setProduct],
                                newPlatformWiseTeamWiseHitsRevenueData, newTeamMembersSubtotaledByTeamHitsRevenueSummary, newteamWiseTeamMemberWiseHitsRevenue,
                                plannedHitsByPlatformByTeam, actualHitsByPlatformByTeam, plannedRevenueByPlatformByTeam, actualRevenueByPlatformByTeam
                            }}>
                                {PlnHitsLoading && PlnRevLoading && ActHitsLoading && ActRevLoading
                                    ? <LinearProgress />
                                    : <MonthlyDetailDataTableComponent displayChart={false} label="Table" />
                                }
                            </SelectedDateRangeContext.Provider>
                        </Grid>
                    </Box>
                </Grid>
            </Tabpanel>
        </Box>
    )
    function Tabpanel(props) {
        const { children, value, index } = props;
        return <>{value === index && <>{children}</>}</>;
    }
};

export default MonthlyDetailPage;
