import React, { useState, useContext } from 'react';
import { LinearProgress } from '@material-ui/core';
import Chart from 'react-apexcharts';
import { format } from 'date-fns';

import { SelectedDateRangeContext } from '../pages/monthly.detail.page';

const Rolling30DaysLineChartRevenueComponent = (props) => {

  const { timePeriod, revrollAxis, revrollAct, revrollPlan, PlnHitsLoading, ActHitsLoading, PlnRevLoading, ActRevLoading } = useContext(SelectedDateRangeContext);

  const [chartTitles] = useState(props.chartTitles);
  const [ChartAxisHitsSummary] = revrollAxis
  const [ChartDataPlannedHitsSummary] = revrollPlan
  const [ChartDataActualHitsSummary] = revrollAct

  const [statePlnHitsLoading] = PlnHitsLoading
  const [stateActHitsLoading] = ActHitsLoading
  const [statePlnRevLoading] = PlnRevLoading
  const [stateActRevLoading] = ActRevLoading

  return (
    <>
      {statePlnHitsLoading && stateActHitsLoading && statePlnRevLoading && stateActRevLoading
        ? <LinearProgress color="primary" />
        : <Chart
          height={275}
          width={'100%'}
          type={props.charttype || "line"}
          options={{
            noData: { text: 'No Data To Build Chart', align: 'center', verticalAlign: 'middle' },
            theme: { mode: 'light', palette: 'palette2' },
            chart: { zoom: { enabled: true } },
            dataLabels: {
              enabled: props.showDataLabels,
              formatter: val => timePeriod && timePeriod[0] === 'day' ? format(new Date(val), 'MMM dd, yyyy') : val
            },
            stroke: { show: true, curve: 'smooth', lineCap: 'round', width: 1.5, dashArray: 0 },
            title: { text: chartTitles.chartTitle, align: 'left' },
            grid: { show: false },
            xaxis: {
              show: false,
              type: timePeriod[0] === 'day' ? 'datetime' : 'category',
              labels: {
                // fontSize: '0.125rem',
                formatter: (val) => timePeriod && timePeriod[0] === 'day' ? format(new Date(val), 'MMM dd, yyyy') : val
              }, axisTicks: { show: false }, categories: ChartAxisHitsSummary
            },
            yaxis: {
              show: true, type: 'number',
              labels: {
                show: true,
                formatter: (val) => Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(val)
              },
              min: 0.00
            },
          }}
          series={[
            { name: chartTitles.seriesAname, data: ChartDataPlannedHitsSummary },
            { name: chartTitles.seriesBname, data: ChartDataActualHitsSummary }
          ]}
        />}
    </>
  )
};

export default Rolling30DaysLineChartRevenueComponent;
