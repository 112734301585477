import React, { useContext } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Box, colors, LinearProgress, makeStyles } from '@material-ui/core';
import { SelectedDateRangeContext } from '../pages/monthly.detail.page'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 125,
        marginTop: 0,
        marginBottom: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    MuiDataGrid: {
        '& .MuiDataGrid-cell': {
            color: colors.grey[700],
            fontSize: "0.75rem !important",
            margin: "0 !important",
        },
        '& .MuiDataGrid-row': {
            '&:last-child': {
                fontWeight: '700 !important',
                textTransform: 'uppercase !important',
            }
        },
        '& .MuiDataGrid-columnHeader': {
            color: colors.blue[900],
            fontSize: "0.4rem !important",
            fontWeight: "500 !important",
            lineHeight: "1.1 !important",
            height: '75px !important',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: "0.75rem !important",
            fontWeight: '500 !important',
            lineHeight: '1.1 !important',
            whiteSpace: 'break-spaces !important',
        }
    },
    MuiDataGridRoot: {
        padding: '0 !important',
        margin: '0 !important',
    },
}));

const MonthlySummaryByTeamMemberDataTable = () => {
    const classes = useStyles();

    const { memberplannedHits, memberactualHits, memberplannedRevenue, memberactualRevenue, PlnHitsLoading, ActHitsLoading, PlnRevLoading, ActRevLoading, membercolsPlnHits, membercolsActHits, membercolsPlnRev, membercolsActRev } = useContext(SelectedDateRangeContext);

    const [plannedHits] = memberplannedHits;
    const [actualHits] = memberactualHits;
    const [plannedRevenue] = memberplannedRevenue;
    const [actualRevenue] = memberactualRevenue;

    const [statePlnHitsLoading] = PlnHitsLoading
    const [stateActHitsLoading] = ActHitsLoading
    const [statePlnRevLoading] = PlnRevLoading
    const [stateActRevLoading] = ActRevLoading

    const [colsPlnHits] = membercolsPlnHits;
    const [colsActHits] = membercolsActHits;
    const [colsPlnRev] = membercolsPlnRev;
    const [colsActRev] = membercolsActRev;

    return (
        <Box margin={1}>
            <div style={{ textAlign: "left", fontWeight: 700, marginTop: 15, color: colors.purple[500] }} className="h5">Team Member Wise Report</div>
            {/* <div style={{ textAlign: "left", marginTop: 15,  marginLeft: 10 }} className="h5"><span style={{ color: colors.blue[900] }}>LinkedIn</span>&nbsp;Planned Hits&nbsp;&nbsp;</div> */}
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Planned Hits</span></div>
            {statePlnHitsLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={plannedHits}
                    columns={colsPlnHits}
                />
            }
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Actual Hits</span></div>
            {stateActHitsLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={actualHits}
                    columns={colsActHits}
                />
            }
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Planned Revenue</span></div>
            {statePlnRevLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={plannedRevenue}
                    columns={colsPlnRev}
                />
            }
            <div style={{ textAlign: "left", marginTop: 15, marginLeft: 20 }} className="h6"><span style={{ color: colors.blue[900] }}>Actual Revenue</span></div>
            {stateActRevLoading ? <LinearProgress style={{ maxWidth: 250 }} color="primary" /> :
                <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    rows={actualRevenue}
                    columns={colsActRev}
                />
            }
        </Box>
    )
}

export default MonthlySummaryByTeamMemberDataTable;
