import { DataGrid } from '@material-ui/data-grid';
import { LinearProgress, makeStyles, colors } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useContext } from 'react';
import { SelectedDateRangeContext } from '../pages/monthly.detail.page';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 125,
        marginTop: 0,
        marginBottom: 0,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    MuiDataGrid: {
        '& .MuiDataGrid-cell': {
            color: colors.grey[700],
            fontSize: "0.75rem !important",
            margin: "0 !important",
        },
        '& .MuiDataGrid-row': {
            '&:last-child': {
                fontWeight: '700 !important',
                textTransform: 'uppercase !important',
            }
        },
        '& .MuiDataGrid-columnHeader': {
            color: colors.blue[900],
            fontSize: "0.4rem !important",
            fontWeight: "500 !important",
            lineHeight: "1.1 !important",
            height: '75px !important',
        },
        '& .MuiDataGrid-columnHeaderTitle': {
            fontSize: "0.75rem !important",
            fontWeight: '500 !important',
            lineHeight: '1.1 !important',
            whiteSpace: 'break-spaces !important',
        }
    },
    MuiDataGridRoot: {
        padding: '0 !important',
        margin: '0 !important',
    },
    tableMainLabel: { textAlign: "left", marginTop: 15, marginLeft: 10, textTransform: 'capitalize' },
    tableSubLabel: { textAlign: "left", marginTop: 15, marginLeft: 20, textTransform: 'capitalize' }
}));

const MonthlySummaryByPlatformByTeamDataTable = (props) => {
    const classes = useStyles();

    const { newPlatformWiseTeamWiseHitsRevenueData, timePeriod } = useContext(SelectedDateRangeContext)
    const [Platform] = useState(props.platform);
    const [Team] = useState(props.teamName);
    const [plnActHitsRev] = useState(props.plnActHitsRev)
    const [DataLoading, setDataLoading] = useState(true);
    const [PlannedOrActual] = useState(props.PlannedOrActual);
    const [HitsOrRevenue] = useState(props.HitsOrRevenue);
    const [FilteredTableData, setFilteredTableData] = useState([]);
    const [TableColumns, setTableColumns] = useState([]);

    const prepareColumns = async (objKeys, HitsOrRevenue) => {
        let colsArray = [];
        Object.keys(objKeys).forEach((col, idx) => {
            let hd, wd, hide, type, desc;
            const valueFormatter = (params) => {
                if (isNaN(params.value))
                    return params.value
                else
                    return Intl.NumberFormat('en-US', { maximumFractionDigits: 2 }).format(params.value)
            }
            idx <= 1 ? hide = true : hide = false;
            HitsOrRevenue === 'Revenue' ? idx <= 4 ? wd = 65 : idx === 5 ? wd = 65 : wd = 54 : idx <= 4 ? wd = 65 : idx === 5 ? wd = 54 : wd = 54;
            idx < 3 ? hd = col.toUpperCase() : hd = timePeriod[0] === 'day' ? new Date(col).getDate() : col;
            idx < 3 ? type = 'text' : type = 'number';
            if (col === "ROW TOTAL" || col === "row_total") {
                hd = "ROW TOTAL";
                wd = 80;
                desc = "ROW TOTAL"
            }
            if (col === "team_member" || col === "mtd" || col === "Mtd") {
                // console.log('*** ', idx, col, ' ***')
                hd = "NAME";
                wd = 80;
                desc = "NAME"
                // console.log('HD :: ', hd, wd);
            } else {
                desc = col;
            }
            let c = { field: col, headerName: hd, description: desc, width: wd, hide: hide, type: type, sortable: false, headerClassName: classes.MuiDataGrid, valueFormatter };
            colsArray.push(c);
        });
        return colsArray;
    };

    useEffect(async () => {
        const filterDataByPlatformByTeam = async () => {
            setDataLoading(true);

            let fd = [];
            fd = await newPlatformWiseTeamWiseHitsRevenueData[plnActHitsRev].filter(row => row.platform === Platform && row.team === Team && Object.keys(row).length > 4).map((t, idx) => ({ "id": idx, ...t }));
            if (fd.length > 0) {
                /* PREPARE COLUMNS */
                // let cols = await prepareColumns(fd[0], HitsOrRevenue);
                // setTableColumns(cols);

                /* CALCULATE SUB-TOTAL ROW AND APPEND */
                let subTotals = await fd.reduce((prev = {}, curr = {}, idx) => {
                    Object.keys(curr).map((key, i) => {
                        if (i <= 3) {
                            return prev[key] = "Sub Total";
                        } else if (i > 3) {
                            let calc = parseFloat(curr[key]) + (parseFloat(prev[key]) || 0);
                            return prev[key] = calc;
                        } else {
                            return curr;
                        }
                    });
                    return prev;
                }, {});
                setFilteredTableData([...fd, subTotals]);
                setTableColumns(await prepareColumns([...fd, subTotals][0], HitsOrRevenue))
            } else {
                setFilteredTableData([]);
                setTableColumns([]);
                setDataLoading(false);
            }

        };

        await filterDataByPlatformByTeam().then(() => setDataLoading(false));

        return () => clearImmediate();
    }, []);

    return (
        <>
            <h5 className={classes.tableMainLabel} ><span style={{ color: colors.blue[900] }}>{Platform}</span>&nbsp;{PlannedOrActual} {HitsOrRevenue}&nbsp;&nbsp;</h5>
            <h6 className={classes.tableSubLabel}><span style={{ color: colors.blue[900] }}>{Team} Team</span></h6>
            {DataLoading
                ? <div><LinearProgress style={{ maxWidth: 250 }} color="primary" /></div>
                : <DataGrid
                    className={classes.MuiDataGrid}
                    disableColumnMenu
                    hideFooter
                    autoHeight
                    density="compact"
                    key={FilteredTableData.id}
                    rows={FilteredTableData}
                    columns={TableColumns.slice(3)}
                />
            }
        </>
    )
}

export default MonthlySummaryByPlatformByTeamDataTable;
