import React, { useState, useEffect } from "react";
import { Box, Button, FormControl, makeStyles, TextField, Table, TableCell, TableBody, TableHead, TableRow, colors, CircularProgress, MenuItem } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Modal from "react-modal";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 150,
    fontSize: "0.5rem !important",
    marginTop: 0,
    marginBottom: 0,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const AddActualsModalComponent = (props) => {

  const classes = useStyles();

  const [addModalOpen, setAddModalOpen] = useState(props.openFlag);
  const [loading, setLoading] = useState(false);
  const [dataRows, setDataRows] = useState([]);
  const [hitDate, setHitDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [products] = useState([
    { label: 'FilingSearch', value: 'fs' },
    { label: 'InverstorsSite', value: 'is' },
    { label: 'Candorium', value: 'cand' },
    // { label: 'Candorium App', value: 'canapp' },
    // { label: 'Candorium Web', value: 'canweb' },
  ]);
  const [product, setProduct] = useState(products[0].value)

  const handleActualHitChange = (idx, val) => {
    let newArr = [...dataRows];
    newArr[idx] = { ...newArr[idx], actual_hit: val };
    console.log(newArr[idx]);
    setDataRows(newArr);
  };

  const handleActualRevenueChange = (idx, val) => {
    let newArr = [...dataRows];
    newArr[idx] = { ...newArr[idx], actual_revenue: val };
    console.log(newArr[idx]);
    setDataRows(newArr);
  };

  const handleSubmission = (event) => {
    event.preventDefault();
    let config = {
      method: "PUT",
      url: `/api/v2/update-actuals`,
      Headers: { "Content-Type": "application/json" },
      data: dataRows,
    };

    axios.request(config).then((result) => {
      alert(`ADD API Response :: ${result.data.message}`);
      setAddModalOpen(props.handleModalClose);
      window.location.reload();
    }).catch((err) => {
      console.error(`Error Received from ADD API :: ${err}`)
      alert(err.response.data.message)
    });
  };

  Modal.setAppElement("#root");

  const getHitsRevenueByDate = async () => {
    setLoading(true);
    await axios.post(`/api/v2/getHitsRevenueDataByHitDate`, { hit_date: format(new Date(hitDate), "yyyy-MM-dd"), product_name: product })
      .then(async (resp) => {
        console.log(`/api/v2/getHitsRevenueDataByHitDate RESP ::::`, resp.data.message);
        if (resp.data.message.length > 0)
          setDataRows(resp.data.message);
        else
          setDataRows([]);
      }).catch((err) => {
        console.log(`Error From /api/v2/getHitsRevenueDataByHitDate ${err}`);
        setDataRows([]);
      });
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    getHitsRevenueByDate();
  }, []);

  return (
    <Modal
      isOpen={props.openFlag}
      onRequestClose={props.handleModalClose}
      shouldCloseOnOverlayClick={false}
      style={{
        overlay: {
          background: "rgb(0,0,0, 0.85",
          zIndex: 11,
        },
        content: {
          color: "#333",
          height: "80vh",
          margin: "auto",
        },
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="flex-start">
        <Box width="50%">
          <h3 style={{ fontWeight: 200, color: colors.blueGrey[500] }}>Enter Actual Hits And Revenue Data</h3>
        </Box>
        <Box width="10%">
          <TextField
            id="ProductsList"
            select
            variant='outlined'
            size='small'
            value={product}
            onChange={(e) => setProduct(e.target.value)}
            menuprops={{ PaperProps: { style: { maxHeight: 200 } } }}
          >
            {products.length > 0 && products.map((pro, index) => (
              <MenuItem key={index} value={pro.value} disabled={pro.value === 'is'} >{pro.label}</MenuItem>
            ))}
          </TextField>
        </Box>
        <Box width="15%">
          <FormControl
            variant="outlined"
            size="small"
            className={classes.formControl}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="HitStart"
                size="small"
                variant="dialog"
                format="MMM dd, yyyy"
                margin="dense"
                InputLabelProps={{ shrink: true }}
                style={{ padding: "0.25rem", maxWidth: 160 }}
                value={hitDate}
                maxDate={new Date()}
                onChange={e => setHitDate(e)}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Box>
        <Box width="10%">
          <Button onClick={getHitsRevenueByDate} variant="contained" size="small" color="primary">get data</Button>
        </Box>
        <Box width="10%"></Box>
        <Box width="5%">
          <Button onClick={props.handleModalClose} variant="text" size="small" color="secondary">close</Button>
        </Box>
      </Box>
      <hr />
      {loading ? <Box width="90%" display="flex" justifyContent="space-around" alignItems="center" alignContent="center"><CircularProgress color="primary" size={50} /></Box> : <>
        {dataRows.length > 0 ? <>
          <Box width="100%">
            <Table size="small" variant="elevation">
              <TableHead>
                <TableRow>
                  <TableCell width={50}>Row ID</TableCell>
                  <TableCell width={125}>Hit Date</TableCell>
                  <TableCell width={50}>Product</TableCell>
                  <TableCell width={75}>Platform</TableCell>
                  <TableCell width={50}>Team</TableCell>
                  <TableCell width={175}>Member Name</TableCell>
                  <TableCell width={75}>Planned Hits</TableCell>
                  <TableCell width={75}>Planned Revenue</TableCell>
                  <TableCell width={75}>Actual Hits</TableCell>
                  <TableCell width={75}>Actual Revenue</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? <CircularProgress /> : <>{dataRows.map((item, idx) => (
                  <TableRow key={item.id}>
                    <TableCell align="left">{item.id}</TableCell>
                    <TableCell align="left">{format(new Date(item.hit_date), 'MMM dd, yyyy')}</TableCell>
                    <TableCell align="left">{item.product_name}</TableCell>
                    <TableCell align="left">{item.social_platform_name}</TableCell>
                    <TableCell align="left">{item.team_name}</TableCell>
                    <TableCell align="left">{item.team_member_name}</TableCell>
                    <TableCell>{item.planned_hit}</TableCell>
                    <TableCell>{item.planned_revenue}</TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        variant="standard"
                        size="small"
                        defaultValue={0}
                        min={0}
                        step={1}
                        id={item.id.toString()}
                        name="Actual Hit Count"
                        InputLabelProps={{ shrink: true }}
                        value={item['actual_hit']}
                        onChange={(e) => handleActualHitChange(idx, e.target.valueAsNumber)}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        type="number"
                        variant="standard"
                        size="small"
                        defaultValue={0}
                        min={0}
                        step={0.5}
                        id={item.id.toString()}
                        InputLabelProps={{ shrink: true }}
                        value={item['actual_revenue']}
                        onChange={(e) => handleActualRevenueChange(idx, e.target.valueAsNumber)}
                      />
                    </TableCell>
                  </TableRow>
                ))}</>}
              </TableBody>
            </Table>
          </Box>
          <hr />
          <Box display="flex" width="100%" justifyContent="flex-end" marginTop={2}>
            <Button onClick={props.handleModalClose} variant="contained" size="small" color="default" style={{ marginRight: "2rem" }} >
              Cancel
            </Button>
            <Button onClick={handleSubmission} variant="contained" size="small" color="primary" >Save</Button>
          </Box>
        </> : <Box width="100%" height="90%" display="flex" justifyContent="center" alignItems="center">
          <h3 style={{ fontWeight: 200, color: colors.orange[400] }}>No Planned Hits / Revenue Data Available For Selected Date&nbsp;-&nbsp;{format(new Date(hitDate), "MMM dd, yyyy")}</h3 >
        </Box>}
      </>}
    </Modal>
  );
};

export default AddActualsModalComponent;
