import axios from "axios";

// const API_URL = `/auth`;

class AuthService {

  async login (username, password) {
    const response = await axios.post('/api/poclogin', { email:username, password });
    if (response.data.success) {
      localStorage.setItem('user', JSON.stringify(response.data.message));
    }
    return response;
  };

  async logout() {
    return localStorage.removeItem("user");
  };

  async register(username, email, password) {
    console.log(`Calling /Signup API ::: ${username} | ${email} | ${password}`);
    const response = await axios.post('/api/signup', { username, email, password });
    console.log(`User Registration Successful -- ${JSON.stringify(response.data)}`);
    return response;
  };

  async getCurrentUser() {
    return await JSON.parse(localStorage.getItem('user'))
  };

} //Class

export default new AuthService();